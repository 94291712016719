const convertDoctorConsultationTimeCsv = data =>
    data.map(
      ({
        医師ナンバー: doctorNo,
        年: year,
        月: month,
        平均診療時間: score,
      }) => ({
        doctorNo: doctorNo ? parseInt(doctorNo, 10) : undefined,
        year: year ? parseInt(year, 10) : undefined,
        month: month ? parseInt(month, 10) : undefined,
        score: score ? parseFloat(Number(score).toFixed(1)) : undefined,
      })
    )
  export default convertDoctorConsultationTimeCsv