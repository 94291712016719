import AcceptedWorkSchedulesList from '@medical/pages/staff/AcceptedWorkSchedulesList/AcceptedWorkSchedulesList'
import { ActivityDetail } from '@medical/pages/staff/ActivityLogs/ActivityDetail/ActivityDetail'
import ActivityList from '@medical/pages/staff/ActivityLogs/ActivityList/ActivityList'
import ActivityLogsDoctorHistory from '@medical/pages/staff/ActivityLogs/ActivityLogsDoctorHistory/ActivityLogsDoctorHistory'
import ActivityLogsStaffHistory from '@medical/pages/staff/ActivityLogs/ActivityLogsStaffHistory/ActivityLogsStaffHistory'
import ActivityLogsUpdateByStaffDetail from '@medical/pages/staff/ActivityLogs/ActivityLogsUpdateByStaff/ActivityLogsUpdateByStaffDetail/ActivityLogsUpdateByStaffDetail'
import ActivityLogsUpdateByStaff from '@medical/pages/staff/ActivityLogs/ActivityLogsUpdateByStaff/ActivityLogsUpdateByStaffList/ActivityLogsUpdateByStaff'
import AnnounceCreate from '@medical/pages/staff/Announces/AnnounceCreate/AnnounceCreate'
import AnnouncesList from '@medical/pages/staff/Announces/AnnouncesList/AnnouncesList'
import AnnounceUpdate from '@medical/pages/staff/Announces/AnnounceUpdate/AnnounceUpdate'
import AvailableShiftGroupList from '@medical/pages/staff/AvailableShiftGroupList/AvailableShiftGroupList'
import AvailableShiftList from '@medical/pages/staff/AvailableShiftList/AvailableShiftList'
import ChangePasswordContainer from '@medical/pages/staff/ChangePassword/ChangePassword'
import ClinicDetail from '@medical/pages/staff/Clinics/ClinicDetail/ClinicDetail'
import ClinicsList from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList'
import ClinicsManage from '@medical/pages/staff/Clinics/ClinicManage/ClinicsManage'
import ClinicUpdate from '@medical/pages/staff/Clinics/ClinicUpdate/ClinicUpdate'
import CreateAvailableShift from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift'
import CreateScheduledShiftGroup from '@medical/pages/staff/CreateScheduledShiftGroup/CreateScheduledShiftGroup'
import CreateWorkSchedule from '@medical/pages/staff/CreateWorkSchedule/CreateWorkSchedule'
import DailyAvailableShift from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift'
import DetailAvailableShift from '@medical/pages/staff/DetailAvailableShift/DetailAvailableShift'
import DetailDoctorIdentity from '@medical/pages/staff/DetailDoctorIdentity/DetailDoctorIdentity'
import DetailScheduleShiftGroup from '@medical/pages/staff/DetailScheduleShiftGroup/DetailScheduleShiftGroup'
import { DetailWaitingRecruitment } from '@medical/pages/staff/DetailWaitingRecruitment/DetailWaitingRecruitment'
import DetailWorkSchedule from '@medical/pages/staff/DetailWorkSchedule/DetailWorkSchedule'
import DetailWorkScheduleCancelShift from '@medical/pages/staff/DetailWorkScheduleCancelShift/DetailWorkScheduleCancelShift'
import StaffUploadDoctorCsv from '@medical/pages/staff/DoctorCsv/DoctorCsv'
import DoctorCsvUpdate from '@medical/pages/staff/DoctorCsvUpdate/DoctorCsvUpdate'
import DoctorCreate from '@medical/pages/staff/Doctors/DoctorCreate/DoctorCreate'
import DoctorDetail from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail'
import StaffDoctorsDeleteRequestList from '@medical/pages/staff/Doctors/DoctorsDeleteRequestList/DoctorsDeleteRequestList'
import DoctorsList from '@medical/pages/staff/Doctors/DoctorsList/DoctorsList'
import StaffDoctorsPending from '@medical/pages/staff/Doctors/DoctorsPendingList/doctorsPendingList'
import DoctorWorkRecords from '@medical/pages/staff/Doctors/DoctorWorkRecord/DoctorWorkRecords'
import DoctorWorkReport from '@medical/pages/staff/Doctors/DoctorWorkReport/DoctorWorkReport'
import NewDoctorsList from '@medical/pages/staff/Doctors/NewDoctorsList/NewDoctorsList'
import PatientSatisfactionByMonth from '@medical/pages/staff/Doctors/PatientSatisfactionByMonth/PatientSatisfactionByMonth'
import PatientSatisfactionByYear from '@medical/pages/staff/Doctors/PatientSatisfactionByYear/PatientSatisfactionByYear'
import PatientSatisfactionResults from '@medical/pages/staff/Doctors/PatientSatisfactionResults/PatientSatisfactionResults'
import CreateDoctorSkill from '@medical/pages/staff/DoctorSkill/CreateDoctorSkill/CreateDoctorSkill'
import DetailDoctorSkill from '@medical/pages/staff/DoctorSkill/DetailDoctorSkill/DetailDoctorSkill'
import DoctorSkill from '@medical/pages/staff/DoctorSkill/DoctorSkill'
import DocumentCreate from '@medical/pages/staff/Documents/DocumentCreate/DocumentCreate'
import DocumentsList from '@medical/pages/staff/Documents/DocumentsList/DocumentsList'
import DocumentUpdate from '@medical/pages/staff/Documents/DocumentUpdate/DocumentUpdate'
import CreateDoctorHourlyWage from '@medical/pages/staff/HourlyPayment/CreateDoctorHourlyWage'
import DetailDoctorHourlyWage from '@medical/pages/staff/HourlyPayment/DetailDoctorHourlyWage'
import HourlyPayment from '@medical/pages/staff/HourlyPayment/HourlyPayment'
import CreateIncentiveSetting from '@medical/pages/staff/Incentive/IncentiveSetting/CreateIncentiveSetting/CreateIncentiveSetting'
import DetailIncentiveSetting from '@medical/pages/staff/Incentive/IncentiveSetting/DetailIncentiveSetting/DetailIncentiveSetting'
import ListOfIncentivesSetting from '@medical/pages/staff/Incentive/IncentiveSetting/IncentiveSettingList'
import DetailOfIncentives from '@medical/pages/staff/Incentive/ListOfIncentives/DetailOfIncentives/DetailOfIncentives'
import ListOfIncentives from '@medical/pages/staff/Incentive/ListOfIncentives/ListOfIncentives'
import IncentiveCsvImport from '@medical/pages/staff/IncentiveCsv/IncentiveCsv'
import Inquiries from '@medical/pages/staff/Inquiries/Inquiries'
import StaffLogin from '@medical/pages/staff/Login/StaffLogin'
import MonthlyAvailableShift from '@medical/pages/staff/MonthlyAvailableShift/MonthlyAvailableShift'
import MonthlyDoctorSalaryConfirmation from '@medical/pages/staff/MonthlyDoctorSalaryConfirmation/MonthlyDoctorSalaryConfirmation'
import CreateMovingExpenses from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/CreateMovingExpenses/CreateMovingExpenses'
import ListMovingExpenses from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/ListMovingExpenses'
import MovingExpensesDetail from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/MovingExpensesDetail'
import ListMovingExpensesOther from '@medical/pages/staff/MovingExpenses/MovingExpensesOther/ListMovingExpensesOther'
import ListMovingExpensesManagement from '@medical/pages/staff/MovingExpenses/MovingExpensesManagement/ListMovingExpensesManagement'
import MovingExpensesByCarDetail from '@medical/pages/staff/MovingExpenses/MovingExpensesManagement/MovingExpensesByCarDetail'
import MovingExpensesOtherDetail from '@medical/pages/staff/MovingExpenses/MovingExpensesOther/MovingExpensesOtherDetail'
import ListMovingExpensesRegistration from '@medical/pages/staff/MovingExpenses/RegistrationMovingExpenses/ListRegistration'
import MovingExpensesRegistrationCreate from '@medical/pages/staff/MovingExpenses/RegistrationMovingExpenses/MovingExpensesRegistrationCreate'
import MovingExpensesRegistrationDetail from '@medical/pages/staff/MovingExpenses/RegistrationMovingExpenses/MovingExpensesRegistrationDetail'
import DoctorsMonthlyTransportationExpenses from '@medical/pages/staff/MovingExpenses/DoctorsMonthlyTransportationExpenses/DoctorsMonthlyTransportationExpenses'
import MyNumberManage from '@medical/pages/staff/MyNumberManage/MyNumberManage'
import MyPage from '@medical/pages/staff/MyPage/MyPage'
import LargeTitleList from '@medical/pages/staff/Portal/components/Categorys'
import MediumTitleList from '@medical/pages/staff/Portal/components/Categorys'
import PortalCreate from '@medical/pages/staff/Portal/PortalCreate/PortalCreate'
import PortalList from '@medical/pages/staff/Portal/PortalList/PortalList'
import PortalUpdate from '@medical/pages/staff/Portal/PortalUpdate/PortalUpdate'
import Categorys from '@medical/pages/staff/QuestionManager/components/Categorys'
import QuestionCreate from '@medical/pages/staff/QuestionManager/QuestionsCreate/QuestionsCreate'
import QuestionsList from '@medical/pages/staff/QuestionManager/QuestionssList/QuestionsList'
import QuestionUpdate from '@medical/pages/staff/QuestionManager/QuestionsUpdate/QuestionsUpdate'
import RecruitmentDoctorDetail from '@medical/pages/staff/RecruitmentDoctorList/RecruitmentDoctorDetail/RecruitmentDoctorDetail'
import RecruitmentDoctorList from '@medical/pages/staff/RecruitmentDoctorList/RecruitmentDoctorList'
import RecruitmentPostCreate from '@medical/pages/staff/RecruitmentPost/RecruitmentPostCreate/RecruitmentPostCreate'
import RecruitmentPostsList from '@medical/pages/staff/RecruitmentPost/RecruitmentPostsList/RecruitmentPostsList'
import RecruitmentPostUpdate from '@medical/pages/staff/RecruitmentPost/RecruitmentPostUpdate/RecruitmentPostUpdate'
import CreateRecruitmentScheduleShift from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/CreateRecruitmentScheduleShift'
import DetailRecruitmentScheduleShift from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/DetailRecruitmentScheduleShift'
import RecruitmentScheduleShiftList from '@medical/pages/staff/RecruitmentScheduleShift/RecruitmentScheduleShiftList/RecruitmentScheduleShiftList'
import RecruitmentUnpublished from '@medical/pages/staff/RecruitmentUnpublished/RecruitmentUnpublished'
import SingleInquiry from '@medical/pages/staff/SingleInquiry/SingleInquiry'
import StaffEmailConfirmation from '@medical/pages/staff/StaffEmailConfirmation/StaffEmailConfirmation'
import StaffForgotPassword from '@medical/pages/staff/StaffForgotPassword/StaffForgotPassword'
import StaffGroupCreate from '@medical/pages/staff/StaffGroups/StaffGroupCreate/StaffGroupCreate'
import StaffGroupDetail from '@medical/pages/staff/StaffGroups/StaffGroupDetail/StaffGroupDetail'
import StaffGroupsList from '@medical/pages/staff/StaffGroups/StaffGroupsList/StaffGroupsList'
import StaffHome from '@medical/pages/staff/StaffHome/StaffHome'
import ClinicsHourlyWageList from '@medical/pages/staff/StaffHourlyWage/ClinicsHourlyWageList/ClinicsHourlyWageList'
import HourlyWageCsv from '@medical/pages/staff/StaffHourlyWage/HourlyWageCsv/HourlyWageCsv'
import HourlyWageCreate from '@medical/pages/staff/StaffHourlyWage/HourlyWagePage/HourlyWageCreate'
import HourlyWageDuplicate from '@medical/pages/staff/StaffHourlyWage/HourlyWagePage/HourlyWageDuplicate'
import HourlyWageUpdate from '@medical/pages/staff/StaffHourlyWage/HourlyWagePage/HourlyWageUpdate'
import StaffResetPassword from '@medical/pages/staff/StaffResetPassword/StaffResetPassword'
import StaffCreate from '@medical/pages/staff/Staffs/StaffCreate/StaffCreate'
import StaffDetail from '@medical/pages/staff/Staffs/StaffDetail/StaffDetail'
import StaffsList from '@medical/pages/staff/Staffs/StaffsList/StaffsList'
import StaffUpdate from '@medical/pages/staff/Staffs/StaffUpdate/StaffUpdate'
import StaffUpdateProfile from '@medical/pages/staff/StaffUpdateProfile/StaffUpdateProfile'
import { WaitingRecruitment } from '@medical/pages/staff/WaitingRecruitment/WaitingRecruitment'
import WorkOutSideClinicDetail from '@medical/pages/staff/WorkOutsideClinic/Detail/WorkOutsideClinicDetail'
import WorkOutSideClinic from '@medical/pages/staff/WorkOutsideClinic/WorkOutsideClinic'
import WorkScheduleCancel from '@medical/pages/staff/WorkScheduleCancelShift/ScheduleCancel'
import WorkScheduleCsv from '@medical/pages/staff/WorkScheduleCsv/WorkScheduleCsv'
import WorkScheduleOverTimeDetail from '@medical/pages/staff/WorkScheduleOverTime/WorkScheduleOverTimeDetail/WorkScheduleOverTimeDetail'
import WorkScheduleOverTimeList from '@medical/pages/staff/WorkScheduleOverTime/WorkScheduleOverTimeList'
import WorkSchedulesList from '@medical/pages/staff/WorkSchedulesList/WorkSchedulesList'
import { GasolinePrice } from '@medical/pages/staff/GasolinePrice/GasolinePrice'
import UpdateMovingExpenses from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/UpdateMovingExpenses/UpdateMovingExpenses'
import Router from './router'
import ManagerDoctorIdentity from '@medical/pages/staff/ManagerDoctorIdentity/ManangerDoctorIdentity'
import { MovingExpenseSetting } from '@medical/pages/staff/MovingExpenses/MovingExpenseSetting/ListMovingExpenseSetting/MovingExpenseSetting'
import { MovingExpensesAddSetting } from '@medical/pages/staff/MovingExpenses/MovingExpenseSetting/MovingExpenseCreateSetting/MovingExpensesAddSetting'
import { MovingExpenseDetailSetting } from '@medical/pages/staff/MovingExpenses/MovingExpenseSetting/MovingExpenseDetailSetting/MovingExpenseDetailSetting'
import EarlySpecialHourlyWageSetting from '@medical/pages/staff/EarlySpecialHourlyWageSetting/EarlySpecialHourlyWageSetting'
import Regions from '@medical/pages/staff/Region/Region'
import DoctorConsultationTimeList from '@medical/pages/staff/DoctorConsultationTimeList/DoctorConsultationTimeList'
import DocTorConsultationCsv from '@medical/pages/staff/DocTorConsultationCsv/DocTorConsultationCsv'

const StaffRouters = {
  staffLogin: {
    description: 'Login page for Staff Login',
    notProtected: true,
    path: Router.staffLogin,
    component: StaffLogin,
  },
  staffForgotPassword: {
    description: 'Login page for Staff Login',
    notProtected: true,
    path: Router.staffForgotPassword,
    component: StaffForgotPassword,
  },
  staffResetPassword: {
    description: 'Login page for Staff Login',
    notProtected: true,
    path: Router.staffResetPassword,
    component: StaffResetPassword,
  },
  staffEmailConfirm: {
    description: 'Staff Email Confirmation page',
    notProtected: true,
    path: Router.staffEmailConfirm,
    component: StaffEmailConfirmation,
  },
  staffHome: {
    description: 'Change password for Staff',
    path: Router.staffHome,
    exact: true,
    component: StaffHome,
  },
  staffMyPage: {
    description: 'Staff my page for profile',
    path: Router.staffMyPage,
    component: MyPage,
    modals: [
      {
        description: 'Update Profile for Staff',
        path: Router.staffUpdateProfile,
        component: StaffUpdateProfile,
        exact: true,
      },
      {
        description: 'Change password for Staff',
        path: Router.staffChangePassword,
        component: ChangePasswordContainer,
        exact: true,
      },
    ],
  },
  staffDailyAvailableShifts: {
    description: 'Staff Daily Available Shifts by all clinics',
    path: [
      Router.staffDailyAvailableShifts,
      Router.staffDailyAvailableShiftsWithoutDay,
      Router.staffDailyAvailableShiftsWithoutMonthDay,
      Router.staffDailyAvailableShiftsWithoutYearMonthDay,
    ],
    component: DailyAvailableShift,
    modals: [
      {
        component: CreateAvailableShift,
        path: Router.staffCreateAvailableShift,
        exact: true,
      },
      {
        component: CreateScheduledShiftGroup,
        path: Router.staffCreateScheduledShiftGroup,
        exact: true,
      },
      {
        component: CreateWorkSchedule,
        path: Router.staffCreateWorkSchedule,
        exact: true,
      },
      {
        component: DetailAvailableShift,
        path: Router.staffDetailAvailableShift,
        exact: true,
      },
      {
        component: DetailWorkSchedule,
        path: Router.staffDetailWorkSchedule,
        exact: true,
      },
      {
        component: DetailScheduleShiftGroup,
        path: Router.staffDetailScheduledShiftGroup,
        exact: true,
      },
    ],
  },
  staffWorkScheduleOverTimes: {
    description: 'Staff List Over Time',
    path: Router.staffOverTime,
    component: WorkScheduleOverTimeList,
    permissionId: 'staffPageWorkScheduleOverTime',
    modals: [
      {
        path: Router.staffOverTimeDetail,
        component: WorkScheduleOverTimeDetail,
        exact: true,
      },
    ],
  },
  staffMonthlyAvailableShifts: {
    description: 'Staff Monthly Available Shifts by all clinics',
    path: [
      Router.staffMonthlyAvailableShifts,
      Router.staffMonthlyAvailableShiftsWithoutYearMonth,
      Router.staffMonthlyAvailableShiftsWithoutMonth,
    ],
    component: MonthlyAvailableShift,
    modals: [
      {
        component: CreateAvailableShift,
        path: Router.staffCreateAvailableShift,
        exact: true,
      },
      {
        component: CreateScheduledShiftGroup,
        path: Router.staffCreateScheduledShiftGroup,
        exact: true,
      },
      {
        component: CreateWorkSchedule,
        path: Router.staffCreateWorkSchedule,
        exact: true,
      },
      {
        component: DetailScheduleShiftGroup,
        path: Router.staffDetailScheduledShiftGroup,
        exact: true,
      },
    ],
  },
  staffHourlyPayment: {
    description: 'Staff Hourly Payment',
    path: [Router.staffHourlyPayment],
    component: HourlyPayment,
    permissionId: 'staffPageDoctorDefaultHourlyWage',
    modals: [
      {
        // permissionId: 'staff'
        description: 'Staff create doctor hourly wage',
        path: [Router.staffCreateDoctorHourlyWage],
        component: CreateDoctorHourlyWage,
        exact: true,
      },
      {
        description: 'Detail doctor hourly wage',
        path: [Router.staffDetailDoctorHourlyWage],
        component: DetailDoctorHourlyWage,
      },
    ],
  },
  staffIncentiveSetting: {
    permissionId: 'staffPageIncentiveSetting',
    description: 'Staff Insentive Setting',
    path: [Router.staffIncentiveSetting],
    component: ListOfIncentivesSetting,
    modals: [
      {
        description: 'Staff Insentive Setting Create',
        component: CreateIncentiveSetting,
        path: Router.staffCreateIncentiveSettingCreate,
        exact: true,
      },
      {
        description: 'Staff Insentive Setting Detail',
        component: DetailIncentiveSetting,
        path: Router.staffCreateIncentiveSettingDetail,
        exact: true,
      },
    ],
  },

  staffIncentiveCsvImport: {
    permissionId: 'staffUploadIncentiveReportCsv',
    description: 'Staff Insentive Import',
    path: [Router.staffIncentiveCsvImport],
    component: IncentiveCsvImport,
  },
  staffIncentive: {
    permissionId: 'staffPageIncentiveSetting',
    description: 'Staff Insentive',
    path: [Router.staffIncentive],
    component: ListOfIncentives,
    modals: [
      {
        description: 'Staff Insentive Detail',
        component: DetailOfIncentives,
        path: Router.staffIncentiveDetail,
        exact: true,
      },
    ],
  },

  staffCancelShifts: {
    description: 'Work Schedule Cancel',
    path: Router.staffCancelShifts,
    component: WorkScheduleCancel,
    modals: [
      {
        component: CreateAvailableShift,
        path: Router.staffCreateAvailableShift,
        exact: true,
      },
      {
        description: 'Detail work schedule cancel',
        component: DetailWorkScheduleCancelShift,
        path: Router.staffCancelShiftsDetail,
        exact: true,
      },
    ],
  },
  staffInquiries: {
    description: 'Staff Inquiries',
    permissionId: 'staffPageInquiries',
    path: Router.staffInquiries,
    component: Inquiries,
    modals: [
      {
        description: 'Staff Inquiry Detail modal',
        component: SingleInquiry,
        path: Router.staffInquiryDetail,
        exact: true,
      },
    ],
  },
  staffQuestions: {
    description: 'Staff Question',
    path: Router.staffQuestions,
    permissionId: 'staffPageQuestions',
    component: QuestionsList,
  },
  staffQuestionCreate: {
    description: 'Staff Create an question',
    permissionId: 'staffPageQuestions',
    path: Router.staffQuestionCreate,
    component: QuestionCreate,
    modals: [
      {
        description: 'Staff Question Category List',
        component: Categorys,
        path: Router.staffQuestionCategory,
        exact: true,
      },
    ],
  },
  staffQuestionUpdate: {
    description: 'Staff Update an question',
    permissionId: 'staffPageQuestions',
    path: Router.staffQuestionUpdate,
    component: QuestionUpdate,
    modals: [
      {
        description: 'Staff Question Category List',
        component: Categorys,
        path: Router.staffQuestionCategory,
        exact: true,
      },
    ],
  },
  staffAnnounces: {
    description: 'Staff Announces',
    permissionId: 'staffPageAnnounces',
    path: Router.staffAnnounces,
    component: AnnouncesList,
  },
  staffAnnounceCreate: {
    description: 'Staff Create an announce',
    permissionId: 'staffPageAnnounces',
    path: Router.staffAnnounceCreate,
    component: AnnounceCreate,
  },
  staffAnnounceUpdate: {
    description: 'Staff Update an announce',
    permissionId: 'staffPageAnnounces',
    path: Router.staffAnnounceUpdate,
    component: AnnounceUpdate,
  },
  staffClinicsManage: {
    description: 'Staff Clinics manage',
    path: Router.staffClinicsManage,
    component: ClinicsManage,
  },
  staffClinics: {
    description: 'Staff Clinics list',
    permissionId: 'staffPageClinics',
    path: Router.staffClinics,
    component: ClinicsList,
    modals: [
      {
        description: 'Staff Clinic Detail modal',
        component: ClinicDetail,
        path: Router.staffClinicDetail,
        exact: true,
      },
      {
        description: 'Staff Clinic Update modal',
        component: ClinicUpdate,
        path: Router.staffClinicUpdate,
        exact: true,
      },
      {
        description: 'Staff Edit Region',
        path: Router.staffRegionEdit,
        permissionId: 'staffPageQuestions',
        component: Regions,
      },
    ],
  },
  staffHourlyWage: {
    description: 'Staff Clinics list',
    permissionId: 'defaultHourlyWageSchedules',
    path: Router.staffHourlyWages,
    component: ClinicsHourlyWageList,
    modals: [
      {
        description: 'Staff Clinic create modal',
        component: HourlyWageCreate,
        path: Router.staffHourlyWageCreate,
        exact: true,
      },
      {
        description: 'Staff Clinic Update modal',
        component: HourlyWageUpdate,
        path: Router.staffHourlyWageDetail,
        exact: true,
      },
      {
        description: 'Staff Clinic duplicate modal',
        component: HourlyWageDuplicate,
        path: Router.staffHourlyWageDuplicate,
        exact: true,
      },
    ],
  },
  staffHourlyWageCsv: {
    description: 'staff upload csv',
    path: Router.staffHourlyWageCsv,
    component: HourlyWageCsv,
    permissionId: 'staffUploadDefaultHourlyWageCSV',
  },
  staffDoctorsMovingExpenses: {
    description: 'Doctor moving expenses',
    path: [Router.staffDoctorsMovingExpenses],
    component: ListMovingExpenses,
    permissionId: 'staffPageTransportationExpense',
  },
  staffDoctorsMovingExpensesRegistration: {
    description: 'Doctor moving expenses register',
    path: Router.staffDoctorsMovingExpensesRegistration,
    component: ListMovingExpensesRegistration,
    permissionId: 'staffPageTransportationExpense',
    modals: [
      {
        description: 'Staff  create',
        path: Router.staffDoctorsMovingExpensesRegistrationCreate,
        component: MovingExpensesRegistrationCreate,
        exact: true,
      },
      {
        description: 'Doctor moving expenses detail',
        path: Router.staffDoctorsMovingExpensesRegistrationDetail,
        component: MovingExpensesRegistrationDetail,
        exact: true,
      },
    ],
  },
  staffDoctorsMovingExpensesOther: {
    description: 'Doctor moving expenses expenses other',
    path: Router.staffDoctorsMovingExpensesOther,
    component: ListMovingExpensesOther,
    permissionId: 'staffPageTransportationExpense',
    modals: [
      {
        description: 'Doctors Moving Expenses Registration Detail ',
        path: Router.staffDoctorsMovingExpensesOtherDetail,
        component: MovingExpensesOtherDetail,
        exact: true,
      },
    ],
  },
  staffDoctorsMovingExpensesSetting: {
    description: 'Doctor moving expenses setting',
    path: Router.staffDoctorsMovingExpensesSetting,
    component: MovingExpenseSetting,
    permissionId: 'staffPageTransportationExpense',
    modals: [
      {
        description: 'Doctor moving expenses add setting',
        path: Router.staffDoctorsMovingExpensesAddSetting,
        component: MovingExpensesAddSetting,
        exact: true,
      },
      {
        description: 'Doctor moving expenses detail setting',
        path: Router.staffDoctorsMovingExpensesDetailSetting,
        component: MovingExpenseDetailSetting,
        exact: true,
      },
    ],
  },
  staffManageDoctorTransportByPersonalCar: {
    description: 'Doctor moving by car expenses',
    path: Router.staffManageDoctorTransportByPersonalCar,
    component: ListMovingExpensesManagement,
    permissionId: 'staffPageTransportationExpense',
    modals: [
      {
        description: 'Staff get gasoline Price ',
        path: Router.staffGasolinePrice,
        component: GasolinePrice,
        exact: true,
      },
    ],
  },
  staffManageDoctorTransportByCarDetail: {
    description: 'Doctors Moving By Car Expenses Registration Detail ',
    path: Router.staffManageDoctorTransportByCarDetail,
    component: MovingExpensesByCarDetail,
  },
  staffDoctorsMonthlyTransportationExpenses: {
    description: 'Staff Get Doctors Monthly Transportation Expenses',
    path: Router.staffDoctorsMonthlyTransportationExpenses,
    component: DoctorsMonthlyTransportationExpenses,
  },
  staffDoctorsMovingExpensesDetail: {
    description: 'Staff Staff Group detail',
    path: Router.staffDoctorsMovingExpensesDetail,
    component: MovingExpensesDetail,
    modals: [
      {
        description: 'Doctor moving expenses create',
        path: Router.staffDoctorsMovingExpensesCreate,
        component: CreateMovingExpenses,
        exact: true,
      },
      {
        description: 'Doctor moving expenses update',
        path: Router.staffDoctorsMovingExpensesUpdate,
        component: UpdateMovingExpenses,
        exact: true,
      },
    ],
  },
  staffStaffs: {
    description: 'Staff Staffs list',
    permissionId: 'staffPageStaffs',
    path: Router.staffStaffs,
    component: StaffsList,
    modals: [
      {
        description: 'Staff Staff create',
        path: Router.staffStaffCreate,
        component: StaffCreate,
        exact: true,
      },
      {
        description: 'Staff Staff detail',
        path: Router.staffStaffDetail,
        component: StaffDetail,
        exact: true,
      },
      {
        description: 'Staff Staff update',
        path: Router.staffStaffUpdate,
        component: StaffUpdate,
        exact: true,
      },
    ],
  },
  staffAvailableShiftGroupList: {
    description: 'Staff Available Shift Group List',
    path: Router.staffAvailableShiftGroupList,
    component: AvailableShiftGroupList,
    permissionId: 'staffPageScheduledAvailableShiftGroup',
    modals: [
      {
        component: CreateScheduledShiftGroup,
        path: Router.staffCreateScheduledShiftGroup,
        exact: true,
      },
      {
        component: DetailScheduleShiftGroup,
        path: Router.staffDetailScheduledShiftGroup,
        exact: true,
      },
    ],
  },
  staffDoctors: {
    description: 'Staff Doctors list',
    path: Router.staffDoctors,
    component: DoctorsList,
    permissionId: 'staffPageDoctors',
    modals: [
      {
        description: 'Staff Doctor create',
        path: Router.staffDoctorCreate,
        component: DoctorCreate,
        exact: true,
      },
      {
        description: 'Staff Doctor detail',
        path: Router.staffDoctorDetail,
        component: DoctorDetail,
        exact: true,
      },
    ],
  },
  staffDoctorsPending: {
    description: 'Staff Doctors list',
    path: Router.staffDoctorsPending,
    component: StaffDoctorsPending,
    permissionId: 'staffPageDoctorsPending',
    modals: [
      {
        description: 'Staff Doctor create',
        path: Router.staffDoctorCreate,
        component: DoctorCreate,
        exact: true,
      },
      {
        description: 'Staff Doctor detail',
        path: Router.staffDoctorDetail,
        component: DoctorDetail,
        exact: true,
      },
    ],
  },
  staffDoctorsDeleteRequestList: {
    description: 'Staff Doctors list',
    path: Router.staffDoctorsDeleteRequestList,
    component: StaffDoctorsDeleteRequestList,
    permissionId: 'staffPageDoctorsDeleteRequestList',
    modals: [
      {
        description: 'Staff Doctor detail',
        path: Router.staffDoctorDetail,
        component: DoctorDetail,
        exact: true,
      },
    ],
  },
  staffDoctorWorkRecords: {
    description: 'Staff Doctor work record',
    path: Router.staffDoctorWorkRecords,
    component: DoctorWorkRecords,
    permissionId: 'staffPageDoctors',
  },
  staffDoctorWorkReport: {
    description: 'Staff Doctor Work Reports calendar page',
    path: [
      Router.staffDoctorWorkReport,
      Router.staffDoctorWorkReportWithoutMonth,
      Router.staffDoctorWorkReportWithoutYearMonth,
    ],
    component: DoctorWorkReport,
    permissionId: 'staffPageDoctors',
  },
  staffDoctorConsultationTimeCsv: {
    description: 'staffPageDoctorConsultationTimeCsvUpload',
    path: Router.staffDoctorConsultationTimeCsv,
    permissionId: 'staffViewPageDoctorConsultationTime',
    component: DocTorConsultationCsv,
  },
  staffDoctorConsultation: {
    description: 'Staff Doctors list',
    path: Router.staffDoctorConsultation,
    component: DoctorConsultationTimeList,
    permissionId: 'staffViewPageDoctorConsultationTime',
  },
  staffPatientSatisfactionResults: {
    description: 'Staff Patient satisfaction questionnaire results',
    path: Router.staffPatientSatisfactionResults,
    component: PatientSatisfactionResults,
    permissionId: 'staffPageDoctorIncentiveReport',
  },
  staffPatientSatisfactionResultsByYear: {
    description: 'Staff Patient satisfaction questionnaire results by year',
    path: [
      Router.staffPatientSatisfactionResultsByYear,
      Router.staffPatientSatisfactionResultsByYearWithoutYear,
    ],
    component: PatientSatisfactionByYear,
    permissionId: 'staffPageDoctorIncentiveReport',
  },
  staffPatientSatisfactionResultsByYearMonth: {
    description:
      'Staff Patient satisfaction questionnaire results by year month',
    path: [
      Router.staffPatientSatisfaction,
      Router.staffPatientSatisfactionWithoutDate,
      Router.staffPatientSatisfactionWithoutMonthDate,
      Router.staffPatientSatisfactionWithoutYearMonthDate,
    ],
    component: PatientSatisfactionByMonth,
    permissionId: 'staffPageDoctorIncentiveReport',
  },
  staffNewDoctorsList: {
    description: 'Staff New Doctors list',
    path: [
      Router.staffNewDoctorsList,
      Router.staffNewDoctorsListWithoutYearMonth,
      Router.staffNewDoctorsListWithoutMonth,
    ],
    component: NewDoctorsList,
    permissionId: 'staffPageNewDoctors',
  },
  monthlyDoctorSalaryConfirmation: {
    description: 'Monthly doctor salary confirmation',
    path: [
      Router.staffMonthlyDoctorSalaryConfirmation,
      Router.staffMonthlyDoctorSalaryConfirmationWithoutMonth,
      Router.staffMonthlyDoctorSalaryConfirmationWithoutYearMonth,
    ],
    component: MonthlyDoctorSalaryConfirmation,
    permissionId: 'staffPageMonthlySalaryConfirmation',
  },
  staffGroups: {
    description: 'Staff Staff Groups list',
    path: Router.staffGroups,
    permissionId: 'staffPageGroups',
    component: StaffGroupsList,
  },
  staffGroupDetail: {
    description: 'Staff Staff Group detail',
    path: Router.staffGroupDetail,
    permissionId: 'staffPageGroups',
    component: StaffGroupDetail,
  },
  staffGroupCreate: {
    description: 'Staff Staff Group detail',
    path: Router.staffGroupCreate,
    permissionId: 'staffPageGroups',
    component: StaffGroupCreate,
  },
  staffWorkScheduleCsv: {
    description: 'Staff Staff Group detail',
    path: Router.staffWorkScheduleCsv,
    component: WorkScheduleCsv,
  },
  staffWorkSchedulesList: {
    description: 'Staff Monthly WorkSchedule List by all clinics',
    permissionId: 'staffPageWorkSchedulesList',
    path: [
      Router.staffWorkSchedulesList,
      Router.staffWorkSchedulesListWithoutYearMonth,
      Router.staffWorkSchedulesListWithoutMonth,
    ],
    component: WorkSchedulesList,
  },
  staffAcceptedWorkSchedulesList: {
    description: 'Staff Monthly Accepted WorkSchedule List by all clinics',
    permissionId: 'staffPageAcceptedWorkSchedulesList',
    path: [
      Router.staffAcceptedWorkSchedulesList,
      Router.staffAcceptedWorkSchedulesListWithoutYearMonth,
      Router.staffAcceptedWorkSchedulesListWithoutMonth,
    ],
    component: AcceptedWorkSchedulesList,
    modals: [
      {
        component: DetailWorkSchedule,
        path: Router.staffDetailWorkSchedule,
        exact: true,
      },
      {
        component: CreateAvailableShift,
        path: Router.staffCreateAvailableShift,
        exact: true,
      },
    ],
  },
  staffAvailableShiftList: {
    description: 'Staff Monthly AvailableShift List by all clinics',
    permissionId: 'staffPageAvailableShiftsList',
    path: [
      Router.staffAvailableShiftList,
      Router.staffAvailableShiftListWithoutYearMonth,
      Router.staffAvailableShiftListWithoutMonth,
    ],
    component: AvailableShiftList,
  },
  staffActivityLogsList: {
    description: 'Staff Latest doctor activities list',
    permissionId: 'staffPageActivityLogsList',
    path: Router.staffActivityLogsList,
    component: ActivityList,
    modals: [
      {
        description: 'Staff doctor activity detail',
        path: Router.staffActivityLogDetail,
        component: ActivityDetail,
        exact: true,
      },
    ],
  },
  staffActivityLogsListUpdateByStaff: {
    description: 'Staff activity log list update by staff',
    permissionId: 'staffPageActivityLogsList',
    path: Router.staffActivityLogsListUpdateByStaff,
    component: ActivityLogsUpdateByStaff,
    modals: [
      {
        description: 'Staff activity log list update by staff detail',
        path: Router.staffActivityLogsListUpdateByStaffDetail,
        component: ActivityLogsUpdateByStaffDetail,
        exact: true,
      },
    ],
  },
  staffRecruitmentScheduleShift: {
    description: 'Staff recruitment schedule shift list',
    // permissionId: 'staffRecruitmentScheduleShift',
    path: Router.staffRecruitmentScheduleShift,
    component: RecruitmentScheduleShiftList,
    modals: [
      {
        description: 'Staff create recruitment schedule shift',
        path: Router.staffRecruitmentScheduleShiftCreate,
        component: CreateRecruitmentScheduleShift,
        exact: true,
      },
      {
        description: 'Staff update recruitment schedule shift',
        path: Router.staffRecruitmentScheduleShiftDetail,
        component: DetailRecruitmentScheduleShift,
        exact: true,
      },
    ],
  },
  staffRecruitmentUnpublished: {
    description: 'Staff recruitment unpublished',
    path: Router.staffRecruitmentUnpublished,
    component: RecruitmentUnpublished,
    modals: [
      {
        component: CreateAvailableShift,
        path: Router.staffCreateAvailableShift,
        exact: true,
      },
      {
        description: 'Staff create recruitment schedule shift',
        path: Router.staffRecruitmentScheduleShiftCreate,
        component: CreateRecruitmentScheduleShift,
        exact: true,
      },
    ],
  },
  staffActivityLogsListStaffHistory: {
    description: 'Staff activity log list staff histories',
    permissionId: 'staffPageActivityLogsList',
    path: Router.staffActivityLogsListStaffHistory,
    component: ActivityLogsStaffHistory,
  },
  staffActivityLogsListDoctorHistory: {
    description: 'Staff activity log list doctor histories',
    permissionId: 'staffPageActivityLogsList',
    path: Router.staffActivityLogsListDoctorHistory,
    component: ActivityLogsDoctorHistory,
  },
  staffMyNumberManage: {
    description: 'Staff Doctor My Number Manage',
    path: Router.staffMyNumberManage,
    permissionId: 'staffPageDoctorMyNumbers',
    component: MyNumberManage,
    modals: [
      {
        component: DetailDoctorIdentity,
        path: Router.staffDetailDoctorIdentity,
        exact: true,
      },
      {
        component: ManagerDoctorIdentity,
        path: Router.staffManangerDoctoridentity,
        exact: true,
      },
    ],
  },
  staffDoctorSkill: {
    description: 'Staff Doctor Skill',
    path: Router.staffDoctorSkill,
    permissionId: 'staffPageDoctors',
    component: DoctorSkill,
    modals: [
      {
        description: 'Staff create doctor skill',
        path: Router.staffCreateDoctorSkill,
        component: CreateDoctorSkill,
        exact: true,
      },
      {
        description: 'Staff doctor skill detail',
        path: Router.staffDetailDoctorSkill,
        component: DetailDoctorSkill,
        exact: true,
      },
    ],
  },
  staffUploadDoctorCsv: {
    description: 'Staff Upload DoctorCsv',
    path: Router.staffUploadDoctorCsv,
    component: StaffUploadDoctorCsv,
    exact: false,
  },
  uploadDoctorCsvUpdate: {
    description: 'Staff Upload DoctorCsvUpdate',
    path: Router.uploadDoctorCsvUpdate,
    component: DoctorCsvUpdate,
  },
  staffDocuments: {
    description: 'Staff Documents',
    permissionId: 'staffPageDocuments',
    path: Router.staffDocuments,
    component: DocumentsList,
  },
  staffDocumentCreate: {
    description: 'Staff Create an document',
    permissionId: 'staffPageDocuments',
    path: Router.staffDocumentCreate,
    component: DocumentCreate,
  },
  staffDocumentUpdate: {
    description: 'Staff Update an document',
    permissionId: 'staffPageDocuments',
    path: Router.staffDocumentUpdate,
    component: DocumentUpdate,
  },
  staffWorkOutsideClinic: {
    description: 'Staff work outside clinic',
    path: Router.staffWorkOutsideClinic,
    permissionId: 'staffPageWorkOutsideClinic',
    component: WorkOutSideClinic,
    modals: [
      {
        description: 'Staff work outside clinic detail',
        path: Router.staffWorkOutsideClinicDetail,
        component: WorkOutSideClinicDetail,
        exact: true,
      },
    ],
  },
  staffPortals: {
    description: 'Staff Portals',
    permissionId: 'staffPagePortal',
    path: Router.staffPortals,
    component: PortalList,
  },
  staffPortalCreate: {
    description: 'Staff Create a portal',
    permissionId: 'staffPagePortal',
    path: Router.staffPortalCreate,
    component: PortalCreate,
    modals: [
      {
        description: 'Update Portal Large Title',
        path: Router.staffLargePortalTitleList,
        component: LargeTitleList,
        exact: true,
      },
      {
        description: 'Update Portal Medium Title',
        path: Router.staffMediumPortalTitleList,
        component: MediumTitleList,
        exact: true,
      },
    ],
  },
  staffPortalUpdate: {
    description: 'Staff Update a portal',
    permissionId: 'staffPagePortal',
    path: Router.staffPortalUpdate,
    component: PortalUpdate,
    modals: [
      {
        description: 'Update Portal Large Title',
        path: Router.staffLargePortalTitleList,
        component: LargeTitleList,
        exact: true,
      },
      {
        description: 'Update Portal Medium Title',
        path: Router.staffMediumPortalTitleList,
        component: MediumTitleList,
        exact: true,
      },
    ],
  },
  waitingRecruitment: {
    description: 'Staff Waiting Recruitment',
    permissionId: 'staffPageWaitingRecruitment',
    path: Router.staffWaitingRecruitment,
    component: WaitingRecruitment,
    modals: [
      {
        description: 'Detail Staff Waiting Recruitment',
        path: Router.staffWaitingRecruitmentDetail,
        component: DetailWaitingRecruitment,
        exact: true,
      },
      {
        description: 'Create Available Shift',
        path: Router.staffCreateAvailableShiftYearMonthDay,
        component: CreateAvailableShift,
        exact: true,
      },
    ],
  },
  staffRecruitmentPosts: {
    description: 'Staff RecruitmentPosts',
    permissionId: 'staffPageRecruitmentPosts',
    path: Router.staffRecruitmentPosts,
    component: RecruitmentPostsList,
  },
  staffRecruitmentPostCreate: {
    description: 'Staff Create an recruitment post',
    permissionId: 'staffPageRecruitmentPosts',
    path: Router.staffRecruitmentPostCreate,
    component: RecruitmentPostCreate,
  },
  staffRecruitmentPostUpdate: {
    description: 'Staff Update an recruitment post',
    permissionId: 'staffPageRecruitmentPosts',
    path: Router.staffRecruitmentPostUpdate,
    component: RecruitmentPostUpdate,
  },
  staffRecruitmentDoctorList: {
    description: 'Staff Recruitment Doctor List',
    path: Router.staffRecruitmentDoctorList,
    permissionId: 'staffPageRecruitmentDoctor',
    component: RecruitmentDoctorList,
    modals: [
      {
        component: RecruitmentDoctorDetail,
        path: Router.staffRecruitmentDoctorDetail,
        exact: true,
      },
    ],
  },
  staffHourlyWageChangeReservation: {
    description: 'staffUpdateEarlySpecialHourlyWageSetting',
    path: Router.staffHourlyWageChangeReservation,
    permissionId: 'staffPageHourlyWageReservation',
    component: EarlySpecialHourlyWageSetting,
  },

}
export default StaffRouters
