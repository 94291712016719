import gql from 'graphql-tag'

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $where2: DoctorConsultationTimeWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          fullName
          email
          doctorConsultationTimes(where: $where2) {
            id
            year
            month
            score
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
