import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CHECK_SHIFT,
  STAFF_UPLOAD_SHIFT,
} from '@medical/constant/permissions'
import { checkStaffPermission, convertUserCsvRecord } from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useState } from 'react'

import {
  CHECK_UPLOAD_DOCTOR_CONSULTATION,
  UPLOAD_DOCTOR_CONSULTATION,
} from './DocTorConsultationCsv.graphql'
import DocTorConsultationCsvScene from './DocTorConsultationCsvScene'
import convertDoctorConsultationTimeCsv from '@medical/libs/convertDoctorConsultationTimeCsv'

const DocTorConsultationCsv = ({ location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const [uploadDoctorConsultations] = useMutation(UPLOAD_DOCTOR_CONSULTATION)
  const [checkUploadDoctorConsultations] = useMutation(
    CHECK_UPLOAD_DOCTOR_CONSULTATION
  )
  const [state, setState] = useState({
    isUploaded: false,
  })
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_SHIFT,
  })
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_SHIFT,
  })
  // if (loading) return <ProgressSpinner />
  // if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    { data: DoctorConsultationCsvData },
    { resetForm }
  ) => {
    await setState({
      isUploaded: true,
    })
    try {
      if (!isUploadPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCsv.uploading'))
        const DoctorConsultationCsvRecords = convertDoctorConsultationTimeCsv(DoctorConsultationCsvData)
        await uploadDoctorConsultations({
          variables: {
            DoctorConsultationCsvRecords,
          },
        })
        popup.clear()
        popup.success(i18n.t('staff.uploadCsv.submissionSuccess'))
        resetForm()
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
    await setState({
      isUploaded: false,
    })
  }

  const checkCsvData = async (
    { data: DoctorConsultationCsvData },
    { setFieldValue }
  ) => {
    try {
      if (!isUploadCheckPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCheckCsv.uploading'))
        const DoctorConsultationCsvRecords = convertDoctorConsultationTimeCsv(DoctorConsultationCsvData)
        const {
          data: { staffCheckUploadDoctorConsultations },
        } = await checkUploadDoctorConsultations({
          variables: {
            DoctorConsultationCsvRecords,
          },
        })

        const messageAddedCsvData = DoctorConsultationCsvData.map(
          (item, index) => ({
            ...item,
            errorMessage:
              staffCheckUploadDoctorConsultations[index].errorMessage,
            checked: true,
          })
        )
        setFieldValue('data', messageAddedCsvData)
        popup.clear()
        popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'))
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
  }

  const downloadSample = () => {
    window.location.href = `${
      process.env.REACT_APP_ENDPOINT
    }/files/医師平均診療時間CSVサンプル.csv?token=${Auth.getToken()}`
  }

  return (
    <DocTorConsultationCsvScene
      popup={popup}
      i18n={i18n}
      onSubmit={onSubmit}
      downloadSample={downloadSample}
      isUploaded={state.isUploaded}
      checkCsvData={checkCsvData}
      isUploadPermitted={isUploadPermitted}
      isUploadCheckPermitted={isUploadCheckPermitted}
    />
  )
}

export default DocTorConsultationCsv
