import gql from 'graphql-tag'

export const UPLOAD_DOCTOR_CONSULTATION = gql`
  mutation UPLOAD_DOCTOR_CONSULTATION(
    $DoctorConsultationCsvRecords: [DoctorConsultationCsvRecords!]!
  ) {
    staffUploadDoctorConsultationCsv(
      DoctorConsultationCsvRecords: $DoctorConsultationCsvRecords
    )
  }
`

export const CHECK_UPLOAD_DOCTOR_CONSULTATION = gql`
  mutation CHECK_UPLOAD_DOCTOR_CONSULTATION(
    $DoctorConsultationCsvRecords: [DoctorConsultationCsvRecords!]!
  ) {
    staffCheckUploadDoctorConsultations(
      DoctorConsultationCsvRecords: $DoctorConsultationCsvRecords
    ) {
      doctorNo
      year
      month
      score
      errorMessage
    }
  }
`
