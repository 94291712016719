import ClinicNameRender from '@medical/components/ClinicNameRender'
import ClinicOpenTimeRender from '@medical/components/ClinicOpenTimeRender'
import {
  COLORS,
  ENUM_ACCEPTED_WS,
  ENUM_MASS_RECRUITMENT,
  ENUM_AVAILABLE_SHIFT,
  ENUM_CANCELED_WS,
  ENUM_FIXED_WS,
  ENUM_NOT_ACCEPTED_WS,
  ENUM_UNCONFIRMED_SHIFT,
} from '@medical/constant'
import Router from '@medical/routes/router'
import moment from 'moment'
import React from 'react'

import { checkOverlapTime } from './checkOverlapTime'
import { removeDuplicate } from './removeDuplicate'
import { Tooltip } from 'antd';

const minutesRounder = time => time

const TimingTypes = {
  working: 'working',
  breaking: 'breaking',
}

const dailyToTimeline = ({
  data: clinicalDepartments,
  clinicalDepartmentIds,
  statuses,
  departmentNames,
  regionIds,
  clinicIds,
}) => {
  const items = []

  function calculateDuration(startTime, endTime) {
    const start = moment(startTime)
    const end = moment(endTime)
    const duration = moment.duration(end.diff(start))
    return duration.asMilliseconds()
  }

  const caculateBreakTimeValues = (workSchedules, type) => {
    let result = []
    if (type === 'workSchedule') {
      // eslint-disable-next-line no-unused-expressions
      const durationWhole = calculateDuration(
        workSchedules?.startTime,
        workSchedules?.endTime
      )

      let durationTime1
      let durationTime2
      let durationTime3
      let durationTime4

      if (workSchedules?.acceptedShift?.splitDateTime1) {
        const timingType = workSchedules?.acceptedShift
          .isSplitDateTime1BreakTime
          ? TimingTypes.breaking
          : TimingTypes.working

        durationTime1 = `${timingType}:${calculateDuration(
          workSchedules?.startTime,
          workSchedules?.acceptedShift?.splitDateTime1
        )}`
      }

      if (workSchedules?.acceptedShift?.splitDateTime2) {
        const timingType = workSchedules?.acceptedShift
          .isSplitDateTime2BreakTime
          ? TimingTypes.breaking
          : TimingTypes.working
        durationTime2 = `${timingType}:${calculateDuration(
          workSchedules?.acceptedShift?.splitDateTime1,
          workSchedules?.acceptedShift?.splitDateTime2
        )}`
      }

      if (workSchedules?.acceptedShift?.splitDateTime3) {
        const timingType = workSchedules?.acceptedShift
          .isSplitDateTime3BreakTime
          ? TimingTypes.breaking
          : TimingTypes.working
        durationTime3 = `${timingType}:${calculateDuration(
          workSchedules?.acceptedShift?.splitDateTime2,
          workSchedules?.acceptedShift?.splitDateTime3
        )}`
      }

      const lastPunch =
        workSchedules?.acceptedShift?.splitDateTime3 ||
        workSchedules?.acceptedShift?.splitDateTime2 ||
        workSchedules?.acceptedShift?.splitDateTime1 ||
        workSchedules?.startTime

      if (lastPunch) {
        const timingType = workSchedules?.acceptedShift?.isStartTimeBreakTime
          ? TimingTypes.breaking
          : TimingTypes.working
        durationTime4 = `${timingType}:${calculateDuration(
          lastPunch,
          workSchedules?.endTime
        )}`
      }

      let count = 0

      result = [durationTime1, durationTime2, durationTime3, durationTime4]
        .filter(Boolean)
        .map(item => {
          const [_type, time] = item.split(':')

          const res = {
            start: +(count / durationWhole).toFixed(4),
            width: +(+time / durationWhole).toFixed(4),
            type: _type,
          }
          count += +time

          return res
        })
    }

    if (type === 'availableShift') {
      const durationWhole = calculateDuration(
        workSchedules?.startTime,
        workSchedules?.endTime
      )

      let durationTime1
      let durationTime2
      let durationTime3
      let durationTime4

      if (workSchedules?.splitDateTime1) {
        const timingType = workSchedules?.isSplitDateTime1BreakTime
          ? TimingTypes.breaking
          : TimingTypes.working

        durationTime1 = `${timingType}:${calculateDuration(
          workSchedules?.startTime,
          workSchedules?.splitDateTime1
        )}`
      }

      if (workSchedules?.splitDateTime2) {
        const timingType = workSchedules?.isSplitDateTime2BreakTime
          ? TimingTypes.breaking
          : TimingTypes.working
        durationTime2 = `${timingType}:${calculateDuration(
          workSchedules?.splitDateTime1,
          workSchedules?.splitDateTime2
        )}`
      }

      if (workSchedules?.splitDateTime3) {
        const timingType = workSchedules?.isSplitDateTime3BreakTime
          ? TimingTypes.breaking
          : TimingTypes.working
        durationTime3 = `${timingType}:${calculateDuration(
          workSchedules?.splitDateTime2,
          workSchedules?.splitDateTime3
        )}`
      }

      const lastPunch =
        workSchedules?.splitDateTime3 ||
        workSchedules?.splitDateTime2 ||
        workSchedules?.splitDateTime1 ||
        workSchedules?.startTime

      if (lastPunch) {
        const timingType = workSchedules?.isStartTimeBreakTime
          ? TimingTypes.breaking
          : TimingTypes.working
        durationTime4 = `${timingType}:${calculateDuration(
          lastPunch,
          workSchedules?.endTime
        )}`
      }

      let count = 0

      result = [durationTime1, durationTime2, durationTime3, durationTime4]
        .filter(Boolean)
        .map(item => {
          const [_type, time] = item.split(':')

          const res = {
            start: +(count / durationWhole).toFixed(4),
            width: +(+time / durationWhole).toFixed(4),
            type: _type,
          }
          count += +time
          return res
        })
    }
    return result
  }

  const groups = clinicalDepartments
    .filter(({ id }) =>
      clinicalDepartmentIds.length === 0
        ? true
        : clinicalDepartmentIds.includes(id)
    )
    .filter(({ clinic }) =>
      clinicIds.length === 0 ? true : clinicIds.includes(clinic.id)
    )
    .filter(({ clinic }) =>
      regionIds.length === 0 ? true : regionIds.includes(clinic.region)
    )
    // .sort((a, b) => a.clinic.order - b.clinic.order)
    .map((department, index) => {
      const { id, name, workSchedules, availableShifts } = department

      const filteredWorkSchedles = workSchedules?.filter(
        ws =>
          ws.deletedStatus !== 'WORKSCHEDULE_REJECT' &&
          ws.deletedStatus !== 'WORKSCHEDULE_DELETE'
      )
      const values = (availableShift, startTime, endTime) => ({
        id: availableShift.id,
        group: id,
        title: `${availableShift.isDoubleRecruitment ? '(2診)' : ''}${
          availableShift.isPublished ? '' : '未 '
        }${moment(startTime).format('HH:mm')} ~ ${moment(endTime).format(
          'HH:mm'
        )}  ${`${availableShift.isSpecial ? '(特別)' : ''}${
          availableShift.isEarlySpecial ? '(早特)' : ''
        }`}`,
        start_time: moment(startTime).unix() * 1000,
        end_time: moment(endTime).unix() * 1000,
        bgColor: availableShift.isDoubleRecruitment
          ? 'rgb(255,0,186)'
          : COLORS.normal,
        borderColor: availableShift.isDoubleRecruitment
          ? 'rgb(255,0,186)'
          : COLORS.normal,
        color: '#fff',
        pathname: Router.get(Router.staffDetailAvailableShift, {
          availableShiftId: availableShift.id,
        }),
        breakTime: caculateBreakTimeValues(availableShift, 'availableShift'),
        case: 1,
        index,
      })
      const workSchedlesDeleted = workSchedules?.filter(ws => !ws.deletedAt)
      availableShifts.forEach(availableShift => {
        const startTime = minutesRounder(availableShift.startTime)
        const endTime = minutesRounder(availableShift.endTime)
        const availableStartTime = moment(
          moment(availableShift.startTime).format('LT'),
          'LT'
        ).valueOf()
        const availableEndTime = moment(
          moment(availableShift.endTime).format('LT'),
          'LT'
        ).valueOf()
        const rangeTime = (availableEndTime - availableStartTime) / 1000
        const isTimeGleOneHourly = rangeTime >= 3600
        if (statuses.includes(ENUM_AVAILABLE_SHIFT))
          items.push(values(availableShift, startTime, endTime))
        else if (
          statuses.includes(ENUM_UNCONFIRMED_SHIFT) ||
          (statuses.includes(ENUM_MASS_RECRUITMENT) && isTimeGleOneHourly)
        ) {
          if (workSchedlesDeleted.length > 0) {
            let rangeTimeWS = 0
            workSchedlesDeleted.forEach(ws => {
              const wsStartTime = moment(
                moment(ws.startTime).format('LT'),
                'LT'
              ).valueOf()
              const wsEndTime = moment(
                moment(ws.endTime).format('LT'),
                'LT'
              ).valueOf()

              if (
                checkOverlapTime(
                  availableStartTime,
                  availableEndTime,
                  wsStartTime,
                  wsEndTime
                )
              ) {
                if (wsEndTime > availableEndTime) {
                  rangeTimeWS += (availableEndTime - wsStartTime) / 1000
                } else if (wsStartTime < availableStartTime) {
                  rangeTimeWS += (wsEndTime - availableStartTime) / 1000
                } else {
                  rangeTimeWS += rangeTimeWS += (wsEndTime - wsStartTime) / 1000
                }
              }
            })
            const isCheck = rangeTime - rangeTimeWS >= 3600
            const isCheckMass = rangeTime - rangeTimeWS === rangeTime
            if (statuses.includes(ENUM_UNCONFIRMED_SHIFT) && isCheck) {
              items.push(items.push(values(availableShift, startTime, endTime)))
            } else if (
              statuses.includes(ENUM_MASS_RECRUITMENT) &&
              isCheckMass
            ) {
              items.push(items.push(values(availableShift, startTime, endTime)))
            }
          } else {
            items.push(items.push(values(availableShift, startTime, endTime)))
          }
        }
      })
      filteredWorkSchedles.forEach(workSchedule => {
        const startDay = moment(workSchedule?.startTime).valueOf()
        const firstWorkday = moment(workSchedule?.doctor.firstWorkday).valueOf()
        const isFirstWorkday = startDay === firstWorkday
        const startTime = minutesRounder(workSchedule?.startTime)
        const endTime = minutesRounder(workSchedule?.endTime)
        let item
        let checkScheduledAvailableShiftGroup
        if (workSchedule?.deletedStatus === 'WORKSCHEDULE_CANCEL') {
          item = {
            id: workSchedule?.id,
            group: id,
            title: `${workSchedule?.acceptedShift.isDoubleRecruitment ?'(2診)' :''}${
              workSchedule?.acceptedShift.isPublished ? '' : '未 '
            }${moment(startTime).format('HH:mm')} ~ ${moment(endTime).format(
              'HH:mm'
            )} (${workSchedule?.doctor.lastname} ${
              workSchedule?.doctor.firstname
            })${isFirstWorkday ? ' ★' : ''}`,
            start_time: moment(startTime).unix() * 1000,
            end_time: moment(endTime).unix() * 1000,
            bgColor: COLORS.canceled,
            borderColor: COLORS.canceled,
            color: '#fff',
            pathname: Router.get(Router.staffDetailWorkSchedule, {
              workScheduleId: workSchedule?.id,
            }),
            acceptedShiftId: workSchedule?.acceptedShift.id,
            breakTime: caculateBreakTimeValues(workSchedule, 'workSchedule'),
            case: 2,
            index,
          }
        } else if (
          workSchedule?.acceptedShift &&
          workSchedule?.acceptedShift.scheduledAvailableShiftGroup &&
          workSchedule?.accepted
        ) {
          if (workSchedule?.acceptedShift.deletedAt) {
            checkScheduledAvailableShiftGroup = true
          } else {
            item = {
              id: workSchedule?.id,
              group: id,
              title: `${workSchedule?.acceptedShift.isDoubleRecruitment ?'(2診)' :''}${
                workSchedule?.acceptedShift.isPublished ? '' : '未 '
              }${moment(startTime).format('HH:mm')} ~ ${moment(endTime).format(
                'HH:mm'
              )} (${workSchedule?.doctor.lastname} ${
                workSchedule?.doctor.firstname
              })${isFirstWorkday ? ' ★' : ''}`,
              start_time: moment(startTime).unix() * 1000,
              end_time: moment(endTime).unix() * 1000,
              bgColor:
                workSchedule?.doctor.workPattern === 'PERMANENT_DOCTOR'
                  ? COLORS.fixedFulltime
                  : COLORS.fixed,
              borderColor:
                workSchedule?.doctor.workPattern === 'PERMANENT_DOCTOR'
                  ? COLORS.fixedFulltime
                  : COLORS.fixed,
              pathname: Router.get(Router.staffDetailWorkSchedule, {
                workScheduleId: workSchedule?.id,
              }),
              acceptedShiftId: workSchedule?.acceptedShift.id,
              breakTime: caculateBreakTimeValues(workSchedule, 'workSchedule'),
              case: 3,
              index,
            }
          }
        } else {
          item = {
            id: workSchedule?.id,
            group: id,
            title: `${workSchedule?.acceptedShift.isDoubleRecruitment ?'(2診)' :''}${
              workSchedule?.acceptedShift.isPublished ? '' : '未 '
            }${moment(startTime).format('HH:mm')} ~ ${moment(endTime).format(
              'HH:mm'
            )} (${workSchedule?.doctor.lastname} ${
              workSchedule?.doctor.firstname
            })${isFirstWorkday ? ' ★' : ''}`,
            start_time: moment(startTime).unix() * 1000,
            end_time: moment(endTime).unix() * 1000,
            bgColor: workSchedule?.accepted
              ? workSchedule?.doctor.workPattern === 'PERMANENT_DOCTOR'
                ? COLORS.fixedFulltime
                : '#fff'
              : COLORS.warning,
            borderColor: workSchedule?.accepted
              ? workSchedule?.doctor.workPattern === 'PERMANENT_DOCTOR'
                ? COLORS.fixedFulltime
                : '#000'
              : COLORS.warning,
            color: workSchedule?.accepted ? '#000' : '#000',
            pathname: Router.get(Router.staffDetailWorkSchedule, {
              workScheduleId: workSchedule?.id,
            }),
            acceptedShiftId: workSchedule?.acceptedShift.id,
            breakTime: caculateBreakTimeValues(workSchedule, 'workSchedule'),
            case: 4,
            index,
          }
        }
        if (
          !checkScheduledAvailableShiftGroup &&
          workSchedule?.accepted &&
          !workSchedule?.deletedStatus
        ) {
          if (statuses.includes(ENUM_ACCEPTED_WS)) {
            items.push(item)
          }
        } else if (
          !checkScheduledAvailableShiftGroup &&
          statuses.includes(ENUM_NOT_ACCEPTED_WS) &&
          !workSchedule?.deletedStatus
        ) {
          items.push(item)
        } else if (
          !checkScheduledAvailableShiftGroup &&
          statuses.includes(ENUM_CANCELED_WS)
        ) {
          items.push(item)
        }
      })
      let clinicName
      const clinic = `${ClinicNameRender({
        clinicalDepartment: department,
      })}`
      // if (clinic.length > 8) {
      //   clinicName = `${clinic.substring(0, 8)}..`
      // } else {
      //   clinicName = clinic
      // }
      clinicName = clinic
      const clinicOpenTime = `${ClinicOpenTimeRender({
        clinicalDepartment: department,
      })}`
      const departmentName = departmentNames[name]
      const area = department.clinic?.Region?.name
      return {
        id,
        title: (
          <div className='tr'>
            <div
              style={{
                transform: 'scale(1)',
                transformOrigin: 'right',
                textAlign: 'center',
                width: '20%',
                fontSize: '12px',
              }}
              title={area}
            >
              {area && area.length > 4
                ? `${area.substring(0, 4)}...`
                : area}
            </div>
            <Tooltip title={clinicName}>
              <div
                style={{width: '20%', fontSize: '12px', textAlign: 'center'}}
              >
                {clinicName && clinicName.length > 7
                  ? `${clinicName.substring(0, 7)}...`
                  : clinicName}
              </div>
            </Tooltip>
            <Tooltip title={departmentName}>
              <div
                style={{
                  transform: 'scale(1)',
                  transformOrigin: 'right',
                  textAlign: 'center',
                  width: '20%',
                  fontSize: '12px',
                }}
              >
                {departmentName && departmentName.length > 5
                  ? `${departmentName.substring(0, 5)}...`
                  : departmentName}
              </div>
            </Tooltip>
            <div
              style={{
                width: '40%',
                fontSize: '12px',
                textAlign: 'center',
                transform: 'scale(1)',
                transformOrigin: 'right',
              }}
              title={clinicOpenTime}
            >
              {clinicOpenTime && clinicOpenTime.length > 20
                ? `${clinicOpenTime.substring(0, 30)}`
                : clinicOpenTime}
            </div>
            <Tooltip
              place="top"
              id="my-tooltip"
            />
          </div>
        ),
        rightTitle: name,
        stackItems: true,
      }
    })
  const uniqueItems = removeDuplicate(items, 'id')
  return {
    groups,
    uniqueItems,
  }
}

export default dailyToTimeline
