import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { ProgressSpinner } from '@medical/components'
import React, { useState } from 'react'
import Router from '@medical/routes/router'
import { Link } from 'react-router-dom'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'

const ClinicsManageScript = ({
  i18n,
  listState,
  setCurrentState,
  runScript,
  clinics,
  results,
  setResults
}) => {
  const optionClinics = clinics
    ?.filter(item => item.isPublished)
    .map(item => ({ value: item.id, label: item.name }))

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.clinics')}
          <div className='breadcrumb'>
            <Link to={Router.home}>{i18n.t('main.home')}</Link>
            <Link to={Router.staffClinics}>
              <i className='pi pi-chevron-right' />
              {i18n.t('staff.menuBar.clinics')}
            </Link>
            <a onClick={() => setCurrentState(listState.state0)}>
              <i className='pi pi-chevron-right' />
              マネジメント
            </a>
            <i className='pi pi-chevron-right' />
            交通費計算
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <div>
            <Formik
              enableReinitialize
              initialValues={{ clinicId: '', scriptName: '' }}
              validationSchema={Yup.object().shape({
                clinicId: Yup.string().required(
                  `${i18n.t('validation.required')}`
                ),
                scriptName: Yup.string().nullable(),
              })}
              onSubmit={runScript}
              render={formikProps => {
                const {
                  isSubmitting,
                  handleSubmit,
                  setFieldValue,
                  values,
                } = formikProps
                const props = { ...formikProps }
                return (
                  <div>
                    {isSubmitting ? (
                      <ProgressSpinner />
                    ) : (
                      <>
                        <div className='tr'>
                          <div
                            className='th'
                            style={{ width: '150px', fontSize: '18px' }}
                          >
                            クリニック名
                          </div>
                          <div className='td'>
                            <Dropdown
                              value={values.clinicId}
                              options={optionClinics}
                              onChange={e => {
                                setFieldValue('clinicId', e.value)
                                setResults([]);
                              }}
                            />
                            <ErrorMessage
                              name='clinicId'
                              component='div'
                              className='signup-alert'
                              style={{ width: '200px', marginRight: '20px' }}
                            />
                          </div>
                        </div>
                        <div className='tr'>
                          <div
                            className='th'
                            style={{ width: '150px', fontSize: '18px' }}
                          >
                            最寄り駅取得
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <div>
                                <Button
                                  type='button'
                                  label='実行'
                                  onClick={() => {
                                    setFieldValue(
                                      'scriptName',
                                      'findNearestStationToAllClinic',
                                      false
                                    )
                                    handleSubmit()
                                    // handleSubmitData(values)
                                  }}
                                  disabled={isSubmitting}
                                />
                              </div>
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findNearestStationToAllClinic'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            padding: '30px 0 0 15px',
                            fontSize: '18px',
                          }}
                        >
                          経路交通費計算
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト1
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic1',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic1'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト2
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic2',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic2'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト3
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic3',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic3'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト4
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic4',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic4'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト5
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic5',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic5'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト6
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic6',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic6'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト7
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic7',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic7'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト8
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic8',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic8'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト9
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic9',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic9'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト10
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic10',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic10'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト11
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic11',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic11'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト12
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic12',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic12'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト13
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic13',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic13'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト14
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic14',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic14'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト15
                          </div>

                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'createTransportationExpenseAutoToClinic15',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'createTransportationExpenseAutoToClinic15'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            padding: '30px 0 0 15px',
                            fontSize: '18px',
                          }}
                        >
                          マイカー距離計算
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト1
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic1',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic1'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト2
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic2',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic2'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト3
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic3',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic3'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト4
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic4',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic4'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト5
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic5',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic5'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト6
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic6',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic6'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト7
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic7',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic7'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト8
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic8',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic8'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト9
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic9',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic9'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト10
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic10',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic10'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト11
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic11',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic11'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト12
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic12',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic12'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト13
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic13',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic13'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト14
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic14',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic14'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト15
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic15',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic15'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト16
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic16',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic16'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト17
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic17',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic17'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト18
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic18',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic18'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト19
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic19',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic19'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th' style={{ width: '150px' }}>
                            スクリプト20
                          </div>
                          <div className='td'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <Button
                                type='button'
                                label='実行'
                                onClick={() => {
                                  setFieldValue(
                                    'scriptName',
                                    'findDistanceFromDoctorAddressToClinic20',
                                    false
                                  )
                                  handleSubmit()
                                }}
                                disabled={isSubmitting}
                              />
                              <div style={{ color: 'red' }}>{`${results.find(
                                result =>
                                  result.scriptName ===
                                  'findDistanceFromDoctorAddressToClinic20'
                              )?.count || ''}`}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClinicsManageScript
