import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { DOCTORS_FIELDS } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import queryString from 'query-string'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'

import { DOCTORS_CONNECTION } from './DoctorConsultationTimeList.graphql'
import DoctorConsultationScene from './DoctorConsultationTimeListScene'
import moment from 'moment'

const DoctorConsultationTimeList = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    fullname,
    email,
    year = moment().format('YYYY'),
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: findNameOrEmail({ fullname, email }),
      registrationStatus_not_in: ['PENDING', 'REJECTED', 'NEW'],
    },
    where2: {
      year: Number(year),
    },
  }
  const socket = useContext(SocketContext)
  const [{ i18n, popup }] = useCustom()
  const client = useApolloClient()
  const { loading, error, data } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const token = Auth.getToken()

  const url = `${process.env.REACT_APP_ENDPOINT}/downloadcsvConsultationTime?token=${token}&year=${Number(year)}`

  const {
    doctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  return (
    <DoctorConsultationScene
      i18n={i18n}
      socket={socket}
      progressList={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
      download={url}
      edges={edges}
      count={count}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      fullname={fullname}
      email={email}
      year={year}
      onPageChange={onPageChange(history, search)}
      history={history}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorConsultationTimeList)
