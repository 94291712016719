import React, { useState } from 'react'
import '../AvailableShiftStyle.css'
import { Checkbox } from 'antd'

const ClinicList = ({
  clinics,
  listRegionData,
  setFieldValue,
  values,
  isMobile,
}) => {
  const [selectedRegions, setSelectedRegions] = useState([])
  // 拠点を改行
  let dataClinicNewLines = clinics.filter(
    item =>
      item.node.name.includes('バックアップ') ||
      item.node.name.includes('院外勤務') ||
      item.node.name.includes('出張インフルエンザワクチン')
  )
  const specialClinic = clinics.filter(item =>
    item.node.name.includes('職域新型コロナワクチン接種')
  )
  if (specialClinic.length > 0) {
    const dataSpecialClinic = {
      node: {
        id: specialClinic.map(({ node }) => node.id).join('&clinics='),
        name: '職域新型コロナワクチン接種',
      },
    }
    dataClinicNewLines = [...dataClinicNewLines, dataSpecialClinic]
  }

  return (
    <>
      {listRegionData?.regions?.sort((a, b) => a.position - b.position).map(i => {
        if (clinics.filter(item => item.node.region === i.id).length > 0)
          return (
            <div
              key={i.id}
              style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  marginTop: '5px'
                }}
              >
                <Checkbox
                  inputId={i.id}
                  value={i.id}
                  onChange={event => {
                    let selectedClinics = [...values.clinics]
                    if (event.target.checked) {
                      // When the region checkbox is checked, add all clinics of that region
                      const selectedClinicIds = clinics
                        .filter(item => item.node.region === i.id)
                        .map(clinic => clinic.node.id)
                      selectedClinics = selectedClinics.filter(
                        clinicId => !selectedClinicIds.includes(clinicId)
                      )
                      selectedClinics.push(...selectedClinicIds)
                      // Add region to selectedRegions
                      setSelectedRegions([
                        ...new Set([...selectedRegions, i.id]),
                      ])
                    } else {
                      // When the region checkbox is unchecked, delete all clinics of that region
                      selectedClinics = selectedClinics.filter(
                        clinicId =>
                          !clinics.some(
                            clinic =>
                              clinic.node.id === clinicId &&
                              clinic.node.region === i.id
                          )
                      )
                      // Remove region from selectedRegions
                      setSelectedRegions(
                        selectedRegions.filter(regionId => regionId !== i.id)
                      )
                    }
                    setFieldValue('clinics', selectedClinics)
                  }}
                  checked={clinics
                    .filter(clinic => clinic.node.region === i.id)
                    .every(clinic => values.clinics.includes(clinic.node.id))}
                />
                <label htmlFor={i.id}>{i.name}</label>
              </div>
              <div style={{ marginLeft: '30px' }}>
                {clinics
                  .filter(item => item.node.region === i.id)
                  .map(({ node }) => (
                    <div
                      className='content-item'
                      key={node.id}
                      style={
                        node.name.length > 13
                          ? {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              marginBottom: '10px',
                            }
                          : {marginBottom: '10px'}
                      }
                    >
                      <Checkbox
                        inputId={node.id}
                        value={node.id}
                        onChange={event => {
                          let selectedClinics = [...values.clinics]
                          if (event.target.checked) {
                            // When the clinic checkbox is checked, add that clinic
                            selectedClinics.push(event.target.value)
                          } else {
                            // When the clinic checkbox is unchecked, delete that clinic
                            const index = selectedClinics.indexOf(
                              event.target.value
                            )
                            if (index > -1) {
                              selectedClinics.splice(index, 1)
                            }
                          }

                          // Check if all clinics in the region are selected, then unselect the region, otherwise deselect the region
                          const regionClinicIds = clinics
                            .filter(clinic => clinic.node.region === i.id)
                            .map(clinic => clinic.node.id)
                          const allClinicsSelected = regionClinicIds.every(
                            clinicId => selectedClinics.includes(clinicId)
                          )

                          if (allClinicsSelected) {
                            // Select region if all clinics in the region are selected
                            setSelectedRegions([
                              ...new Set([...selectedRegions, i.id]),
                            ])
                          } else {
                            // Uncheck region if clinic is not selected
                            setSelectedRegions(
                              selectedRegions.filter(
                                regionId => regionId !== i.id
                              )
                            )
                          }

                          setFieldValue('clinics', selectedClinics)
                        }}
                        checked={values.clinics.includes(node.id)}
                      />
                      <label htmlFor={node.id}>{node.name}</label>
                    </div>
                  ))}
              </div>
            </div>
          )
      })}

      <div
        style={{
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            marginTop: '5px'
          }}
        >
          <Checkbox
            onChange={event => {
              let selectedClinics = [...values.clinics]
              if (event.target.checked) {
                // When the checkbox is checked, add all clinics of no region area
                const selectedClinicIds = clinics
                  .filter(
                    item =>
                      !item.node.region &&
                      !dataClinicNewLines.includes(item) &&
                      !item.node.name.includes('職域新型コロナワクチン接種')
                  )
                  .map(clinic => clinic.node.id)
                selectedClinics = selectedClinics.filter(
                  clinicId => !selectedClinicIds.includes(clinicId)
                )
                selectedClinics.push(...selectedClinicIds)
              } else {
                // When the checkbox is checked, remove all clinics of no region area
                selectedClinics = selectedClinics.filter(
                  clinicId =>
                    !clinics.some(
                      clinic =>
                        clinic.node.id === clinicId &&
                        !clinic.node.region &&
                        !dataClinicNewLines.includes(clinic) &&
                        !clinic.node.name.includes('職域新型コロナワクチン接種')
                    )
                )
              }
              setFieldValue('clinics', selectedClinics)
            }}
            checked={clinics
              .filter(
                clinic =>
                  !clinic.node.region &&
                  !dataClinicNewLines.includes(clinic) &&
                  !clinic.node.name.includes('職域新型コロナワクチン接種')
              )
              .every(clinic => values.clinics.includes(clinic.node.id))}
          />
          <label>{'エリアなし'}</label>
        </div>
        <div style={{ marginLeft: '30px' }}>
          {clinics
            .filter(
              item =>
                !dataClinicNewLines.includes(item) &&
                !item.node.name.includes('職域新型コロナワクチン接種') &&
                !item.node.region
            )
            .map(({ node }) => (
              <div
                className='content-item'
                key={node.id}
                style={
                  node.name.length > 13
                    ? {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginBottom: '10px',
                      }
                    : {marginBottom: '10px',}
                }
              >
                <Checkbox
                  inputId={node.id}
                  value={node.id}
                  onChange={event => {
                    const selectedClinics = [...values.clinics]
                    if (event.target.checked) {
                      selectedClinics.push(event.target.value)
                    } else {
                      selectedClinics.splice(
                        selectedClinics.indexOf(event.target.value),
                        1
                      )
                    }
                    setFieldValue('clinics', selectedClinics)
                  }}
                  checked={values.clinics.indexOf(node.id) !== -1}
                />
                <label htmlFor={node.id}>{node.name}</label>
              </div>
            ))}
        </div>
      </div>

      <div
        style={{
          whiteSpace: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            marginTop: '5px'
          }}
        >
          <Checkbox
            onChange={event => {
              let selectedClinics = [...values.clinics]
              if (event.target.checked) {
                const selectedClinicIds = dataClinicNewLines.map(
                  clinic => clinic.node.id
                )
                const specialClinicIds = specialClinic.map(
                  clinic => clinic.node.id
                )
                selectedClinics = selectedClinics.filter(
                  clinicId =>
                    !selectedClinicIds.includes(clinicId) &&
                    !specialClinicIds.includes(clinicId)
                )
                selectedClinics.push(...selectedClinicIds, ...specialClinicIds)
              } else {
                selectedClinics = selectedClinics.filter(
                  clinicId =>
                    !dataClinicNewLines.some(
                      clinic => clinic.node.id === clinicId
                    ) &&
                    !specialClinic.some(clinic => clinic.node.id === clinicId)
                )
              }
              setFieldValue('clinics', selectedClinics)
            }}
            checked={dataClinicNewLines
              .map(clinic => clinic.node.id)
              .concat(specialClinic.map(clinic => clinic.node.id))
              .every(clinicId => values.clinics.includes(clinicId))}
          />
          <label>{'その他'}</label>
        </div>
        <div style={{ marginLeft: '30px' }}>
          {dataClinicNewLines.map(({ node }) => {
            let isChecked = false
            if (node.name === '職域新型コロナワクチン接種') {
              const specialClinicId = node.id.split('&clinics=')
              isChecked =
                values.clinics.filter(item => specialClinicId.includes(item))
                  .length > 0
            }
            return (
              <div
                className='content-item'
                key={node.id}
                style={
                  node.name.length > 13 && !isMobile
                    ? {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginBottom: '10px',
                      }
                    : {marginBottom: '10px',}
                }
              >
                <Checkbox
                  inputId={node.id}
                  value={node.id}
                  onChange={event => {
                    const selectedClinics = [...values.clinics]
                    if (event.target.checked) {
                      selectedClinics.push(event.target.value)
                    } else {
                      selectedClinics.splice(
                        selectedClinics.indexOf(event.target.value),
                        1
                      )
                    }
                    setFieldValue('clinics', selectedClinics)
                  }}
                  checked={values.clinics.indexOf(node.id) !== -1 || isChecked}
                />
                <label htmlFor={node.id}>{node.name}</label>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ClinicList
