import { Checkbox, TimePicker, Input } from 'antd'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { KATAKANA_REG_EXP, NAME_REG_EXP } from '@medical/constant'
import moment from 'moment'
import React from 'react'
import Router from '@medical/routes/router'
import { Link } from 'react-router-dom'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'

const ClinicsManageCreateClinic = ({
  i18n,
  listState,
  setCurrentState,
  onCreateClinic,
  corporations,
}) => {
  const katakanaNameRegex = Yup.string()
    .matches(NAME_REG_EXP, i18n.t('validation.noSpecialCharacter'))
    .matches(KATAKANA_REG_EXP, i18n.t('validation.katakanaOnly'))
  const options = [
    {
      value: 1,
      label: '掲載',
    },
    {
      value: 0,
      label: '未掲載',
    },
  ]
  const optionsIsOutSide = [
    {
      value: 1,
      label: '有',
    },
    {
      value: 0,
      label: '無',
    },
  ]
  const optionsClinicType = [
    {
      value: 'PAID',
      label: '有給',
    },
    {
      value: 'BACK_UP',
      label: 'バックアップシフトシフト',
    },
    {
      value: 'NORMAL',
      label: 'ノーマル',
    },
  ]
  const optionsCorporation = corporations.map(item => {
    return {
      value: item.id,
      label: item.name,
    }
  })
  const { RangePicker } = TimePicker
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('validation.email.email'))
      .required(i18n.t('validation.email.required')),
    clinicName: Yup.string().required(
      `${i18n.t('main.clinicName')}${i18n.t('validation.required')}`
    ),
    address: Yup.string().required(
      `${i18n.t('main.address')}${i18n.t('validation.required')}`
    ),
    clinicNameKana: katakanaNameRegex.required(
      `${i18n.t('main.clinicNameKana')}${i18n.t('validation.required')}`
    ),
    order: Yup.string()
      .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
      .required(`クリニック番号${i18n.t('validation.required')}`),
    isPublished: Yup.boolean().required(`掲載${i18n.t('validation.required')}`),
    isSearchDisplay: Yup.boolean().required(
      `医師サイト側のシフト検索に表示${i18n.t('validation.required')}`
    ),
    corporationId: Yup.string().required(
      `運営会社${i18n.t('validation.required')}`
    ),
    internalMedicineCode: Yup.string().when('isCreateInternalMedicine', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayInternalMedicine: Yup.string().when('isCreateInternalMedicine', {
      is: true,
      then: Yup.string().required(`${i18n.t('validation.required')}`),
    }),
    childrenMedicineCode: Yup.string().when('isCreateChildrenMedicine', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayChildrenMedicine: Yup.string().when('isCreateChildrenMedicine', {
      is: true,
      then: Yup.string().required(`${i18n.t('validation.required')}`),
    }),
    childrenVaccineCode: Yup.string().when('isCreateChildrenVaccine', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayChildrenVaccine: Yup.string().when('isCreateChildrenVaccine', {
      is: true,
      then: Yup.string().required(`${i18n.t('validation.required')}`),
    }),
    internalVaccineCode: Yup.string().when('isCreateInternalVaccine', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayInternalVaccine: Yup.string().when('isCreateInternalVaccine', {
      is: true,
      then: Yup.string().required(`${i18n.t('validation.required')}`),
    }),
  })
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.clinics')}
          <div className='breadcrumb'>
            <Link to={Router.home}>{i18n.t('main.home')}</Link>
            <Link to={Router.staffClinics}>
              <i className='pi pi-chevron-right' />
              {i18n.t('staff.menuBar.clinics')}
            </Link>
            <a onClick={() => setCurrentState(listState.state0)}>
              <i className='pi pi-chevron-right' />
              マネジメント
            </a>
            <i className='pi pi-chevron-right' />
            新規拠点作成
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <div>
            <Formik
              initialValues={{
                corporationId: corporations && corporations[0]?.id,
                type: 'NORMAL',
                clinicName: '',
                clinicNameKana: '',
                address: '',
                email: '',
                order: '',
                isSearchDisplay: 1,
                isPublished: 1,
                isOutside: 0,
                internalMedicineCode: '',
                isDisplayInternalMedicine: 1,
                childrenMedicineCode: '',
                isDisplayChildrenMedicine: 1,
                childrenVaccineCode: '',
                isDisplayChildrenVaccine: 1,
                internalVaccineCode: '',
                isDisplayInternalVaccine: 1,
                startTimeMonday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeMonday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeTuesday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeTuesday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeWednesday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeWednesday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeThursday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeThursday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeFriday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeFriday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeSaturday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeSaturday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeSunday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeSunday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
                startTimeHoliday: moment()
                  .hour(9)
                  .minute(0)
                  .second(0),
                endTimeHoliday: moment()
                  .hour(21)
                  .minute(0)
                  .second(0),
              }}
              validationSchema={validationSchema}
              onSubmit={onCreateClinic}
              render={formikProps => {
                const { setFieldValue, handleSubmit, values } = formikProps
                const props = { ...formikProps }
                return (
                  <div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`name(${i18n.t('main.clinicName')})`}
                      </div>
                      <div className='td'>
                        <Input
                          onChange={e => {
                            setFieldValue('clinicName', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='clinicName'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`address(${i18n.t('main.address')})`}
                      </div>
                      <div className='td'>
                        <Input
                          onChange={e => {
                            setFieldValue('address', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='address'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`email(${i18n.t('main.email')})`}
                      </div>
                      <div className='td'>
                        <Input
                          onChange={e => {
                            setFieldValue('email', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='email'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`nameKana(${i18n.t('main.clinicNameKana')})`}
                      </div>
                      <div className='td'>
                        <Input
                          onChange={e => {
                            setFieldValue('clinicNameKana', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='clinicNameKana'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`order(クリニック番号)`}
                        <p style={{ color: 'red' }}>※DB確認</p>
                      </div>
                      <div className='td'>
                        <Input
                          onChange={e => {
                            setFieldValue('order', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='order'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div
                      style={{ fontWeight: 'bold', padding: '15px 0 0 15px' }}
                    >
                      開院時間
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.monday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[values.startTimeMonday, values.endTimeMonday]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeMonday`, moment(value[0]))
                            setFieldValue(`endTimeMonday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnMonday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.tuesday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[
                            values.startTimeTuesday,
                            values.endTimeTuesday,
                          ]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeTuesday`, moment(value[0]))
                            setFieldValue(`endTimeTuesday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnTuesday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.wednesday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[
                            values.startTimeWednesday,
                            values.endTimeWednesday,
                          ]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(
                              `startTimeWednesday`,
                              moment(value[0])
                            )
                            setFieldValue(`endTimeWednesday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnWednesday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.thursday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[
                            values.startTimeThursday,
                            values.endTimeThursday,
                          ]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeThursday`, moment(value[0]))
                            setFieldValue(`endTimeThursday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnThursday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.friday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[values.startTimeFriday, values.endTimeFriday]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeFriday`, moment(value[0]))
                            setFieldValue(`endTimeFriday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnFriday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.saturday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[
                            values.startTimeSaturday,
                            values.endTimeSaturday,
                          ]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeSaturday`, moment(value[0]))
                            setFieldValue(`endTimeSaturday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnSaturday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.sunday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[values.startTimeSunday, values.endTimeSunday]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeSunday`, moment(value[0]))
                            setFieldValue(`endTimeSunday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnSunday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.holiday')}
                      </div>
                      <div style={{ paddingRight: '10px' }}>
                        <RangePicker
                          value={[
                            moment(values.startTimeHoliday),
                            moment(values.endTimeHoliday),
                          ]}
                          format='HH:mm'
                          allowClear={false}
                          disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                          placeholder={['開始時刻', '終了時刻']}
                          hideDisabledOptions
                          onChange={(value, timeString) => {
                            setFieldValue(`startTimeHoliday`, moment(value[0]))
                            setFieldValue(`endTimeHoliday`, moment(value[1]))
                          }}
                        />
                      </div>
                      <div className='checkbox'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              onChange={e => {
                                setFieldValue(
                                  `isAvailableOnHoliday`,
                                  e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`isPublished(掲載)`}
                      </div>
                      <div className='td'>
                        <Dropdown
                          value={values.isPublished}
                          options={options}
                          onChange={e => {
                            setFieldValue('isPublished', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='isPublished'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`isOutside(院外勤務)`}
                      </div>
                      <div className='td'>
                        <Dropdown
                          value={values.isOutside}
                          options={optionsIsOutSide}
                          onChange={e => {
                            setFieldValue('isOutside', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div
                        className='th'
                        style={{ width: '200px', whiteSpace: 'wrap' }}
                      >
                        {`messageIntroduce(初回勤務メッセージ)`}
                      </div>
                      <div className='td'>
                        <Input.TextArea
                          onChange={e => {
                            setFieldValue('messageIntroduce', e.target.value)
                          }}
                          autoSize={{
                            minRows: 3,
                            maxRows: 6,
                          }}
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`type(クリニックタイプ)`}
                      </div>
                      <div className='td'>
                        <Dropdown
                          value={values.type}
                          options={optionsClinicType}
                          placeholder='分類選択'
                          onChange={e => {
                            setFieldValue('type', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        {`nearestStations(最寄り駅)`}
                      </div>
                      <div className='td'>
                        <Input
                          onChange={e => {
                            setFieldValue('nearestStations', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div
                        className='th'
                        style={{ width: '200px', whiteSpace: 'wrap' }}
                      >
                        {`isSearchDisplay(医師サイト側のシフト検索に表示)`}
                      </div>
                      <div className='td'>
                        <Dropdown
                          value={values.isSearchDisplay}
                          options={optionsIsOutSide}
                          onChange={e => {
                            setFieldValue('isSearchDisplay', e.target.value)
                          }}
                        />
                        <ErrorMessage
                          name='isSearchDisplay'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='tr'>
                      <div
                        className='th'
                        style={{ width: '200px', whiteSpace: 'wrap' }}
                      >
                        {`Corporation(運営会社)`}
                      </div>
                      <div className='td'>
                        <Dropdown
                          value={values.corporationId}
                          options={optionsCorporation}
                          onChange={e => {
                            setFieldValue('corporationId', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{ fontWeight: 'bold', padding: '15px 0 0 15px' }}
                    >
                      診療科
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        作成
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <div
                            style={{
                              width: '200px',
                              marginRight: '20px',
                              display: 'flex',
                              gap: '10px',
                            }}
                          >
                            <p>departmentCode</p>
                            <p style={{ color: 'red', fontWeight: 'bolder' }}>
                              ※DB確認
                            </p>
                          </div>
                          <div>isDisplay</div>
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          onChange={e => {
                            setFieldValue(
                              'isCreateInternalMedicine',
                              e.target.checked
                            )
                          }}
                        />
                        内科
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            onChange={e => {
                              setFieldValue(
                                'internalMedicineCode',
                                e.target.value
                              )
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayInternalMedicine}
                            options={options}
                            onChange={e => {
                              setFieldValue(
                                'isDisplayInternalMedicine',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='internalMedicineCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayInternalMedicine'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          onChange={e => {
                            setFieldValue(
                              'isCreateChildrenMedicine',
                              e.target.checked
                            )
                          }}
                        />
                        小児科
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            onChange={e => {
                              setFieldValue(
                                'childrenMedicineCode',
                                e.target.value
                              )
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayChildrenMedicine}
                            options={options}
                            onChange={e => {
                              setFieldValue(
                                'isDisplayChildrenMedicine',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='childrenMedicineCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayChildrenMedicine'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          onChange={e => {
                            setFieldValue(
                              'isCreateChildrenVaccine',
                              e.target.checked
                            )
                          }}
                        />
                        小児科ワクチン
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            onChange={e => {
                              setFieldValue(
                                'childrenVaccineCode',
                                e.target.value
                              )
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayChildrenVaccine}
                            options={options}
                            onChange={e => {
                              setFieldValue(
                                'isDisplayChildrenVaccine',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='childrenVaccineCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayChildrenVaccine'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          onChange={e => {
                            setFieldValue(
                              'isCreateInternalVaccine',
                              e.target.checked
                            )
                          }}
                        />
                        内科ワクチン
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            onChange={e => {
                              setFieldValue(
                                'internalVaccineCode',
                                e.target.value
                              )
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayInternalVaccine}
                            options={options}
                            onChange={e => {
                              setFieldValue(
                                'isDisplayInternalVaccine',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='internalVaccineCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayInternalVaccine'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='modal-bottom'>
                      <Button
                        type='submit'
                        className='reflect-button'
                        onClick={handleSubmit}
                        label={i18n.t('staff.editClinic.reflect')}
                      />
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClinicsManageCreateClinic
