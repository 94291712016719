import './styles.css'

import { useMutation } from '@apollo/react-hooks'
import ClinicNameRender from '@medical/components/ClinicNameRender/index.js'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownForSignup'
import InputTextarea from '@medical/components/InputTextarea'
import TextInput from '@medical/components/TextInput'
import { splitLogicReverse } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency.js'
import ShiftFormSplits from '@medical/pages/staff/components/ShiftFormSplits'
import { STAFF_DELETE_DOCTOR_SUBSIDY } from '@medical/pages/staff/DetailWorkSchedule/DetailWorkSchedule.graphql'
import { InputNumber, Switch } from 'antd'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import * as Yup from 'yup'
import Editor from '@medical/components/Editor'
import Router from '@medical/routes/router'
import DetailAvailableShiftTemplateMail from '../DetailAvailableShift/DetailAvailableShiftTemplateMail'
import styles from './Style.js'
import { WorkScheduleComment } from './WorkScheduleComment.js'
import { WorkScheduleType } from './WorkScheduleType.js'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Checkbox as CheckboxAntd } from 'antd'

const DetailWorkScheduleScene = ({
  i18n,
  onSubmit,
  workSchedule,
  // availableShifts,
  activitiesHistory,
  postUpdateHistory,
  staffMe,
  accept,
  cancel,
  reject,
  restore,
  update,
  isMoneyPermitted,
  isUpdatePermitted,
  isRejectPermitted,
  isCancelPermitted,
  isRestorePermitted,
  isAcceptPermitted,
  isAddPermitted,
  isDeletePermitted,
  isViewCountWorkScheduleDetailPermitted,
  isGoldWare,
  departmentNames,
  doctorHourlyWageRange,
  countDoctorWorkedInClinic,
  popup,
  isChangeConfirmedShiftsStatusPermitted,
  location,
  state,
  isDeleteAllComment,
  dataDoctorConsultationTime,
}) => {
  const [staffDeleteDoctorSubsidy] = useMutation(STAFF_DELETE_DOCTOR_SUBSIDY)
  const validationSchema = Yup.object().shape({
    date: Yup.date(i18n.t('validation.date.required')).required(
      i18n.t('validation.date.required')
    ),
    startTime: Yup.date('Should be string').required(''),
    splits: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required(i18n.t('validation.date.required')),
        hourlyWage: isMoneyPermitted
          ? Yup.number().required(i18n.t('validation.hourlyWage.required'))
          : undefined,
      })
    ),
    acceptedReason:
      !isGoldWare &&
      Yup.string()
        .nullable()
        .max(4500, i18n.t('validation.max', { max: 4500 })),
    deletedReason:
      !isGoldWare &&
      Yup.string()
        .nullable()
        .max(300, i18n.t('validation.max', { max: 300 })),
    rejectedReason: Yup.string().when('rejecting', {
      is: true,
      then: Yup.string()
        .required(
          `${i18n.t('main.deletedReason')}${i18n.t('validation.required')}`
        )
        .nullable()
        .max(300, i18n.t('validation.max', { max: 300 })),
      otherwise: Yup.string().nullable(),
    }),
    doctorSubsidy: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          title: Yup.string()
            .nullable()
            .required(i18n.t('validation.doctorSubsidy')),
          money: Yup.number()
            .nullable()
            .required(i18n.t('validation.doctorSubsidy')),
        })
      ),
  })
  const optionsKnowAboutShift = [
    {
      value: 'DIRECT',
      description: '直接',
    },
    {
      value: 'REFERENCE_COMPANY',
      description: '紹介会社',
    },
    {
      value: 'OTHER',
      description: 'その他',
    },
  ]
  const actualTime = ws => {
    const result = []

    if (ws.splitDateTime1 && ws.splitDateTime2 && ws.splitDateTime3) {
      result.push(
        `${moment(ws.startTime).format('HH:mm')}～${moment(
          ws.splitDateTime1
        ).format('HH:mm')} ${ws.isSplitDateTime1BreakTime ? '(休憩時間)' : ''}`
      )

      result.push(
        `${moment(ws.splitDateTime1).format('HH:mm')}～${moment(
          ws.splitDateTime2
        ).format('HH:mm')} ${ws.isSplitDateTime2BreakTime ? '(休憩時間)' : ''}`
      )
      result.push(
        `${moment(ws.splitDateTime2).format('HH:mm')}～${moment(
          ws.splitDateTime3
        ).format('HH:mm')} ${ws.isSplitDateTime3BreakTime ? '(休憩時間)' : ''}`
      )
      result.push(
        `${moment(ws.splitDateTime3).format('HH:mm')}～${moment(
          ws.endTime
        ).format('HH:mm')} ${ws.isStartTimeBreakTime ? '(休憩時間)' : ''}`
      )
    } else if (ws.splitDateTime1 && ws.splitDateTime2) {
      result.push(
        `${moment(ws.startTime).format('HH:mm')}～${moment(
          ws.splitDateTime1
        ).format('HH:mm')} ${ws.isSplitDateTime1BreakTime ? '(休憩時間)' : ''}`
      )

      result.push(
        `${moment(ws.splitDateTime1).format('HH:mm')}～${moment(
          ws.splitDateTime2
        ).format('HH:mm')} ${ws.isSplitDateTime2BreakTime ? '(休憩時間)' : ''}`
      )
      result.push(
        `${moment(ws.splitDateTime2).format('HH:mm')}～${moment(
          ws.endTime
        ).format('HH:mm')} ${ws.isStartTimeBreakTime ? '(休憩時間)' : ''}`
      )
    } else if (ws.splitDateTime1) {
      result.push(
        `${moment(ws.startTime).format('HH:mm')}～${moment(
          ws.splitDateTime1
        ).format('HH:mm')} ${ws.isSplitDateTime1BreakTime ? '(休憩時間)' : ''}`
      )
      result.push(
        `${moment(ws.splitDateTime1).format('HH:mm')}～${moment(
          ws.endTime
        ).format('HH:mm')} ${ws.isStartTimeBreakTime ? '(休憩時間)' : ''}`
      )
    } else {
      result.push(
        `${moment(ws.startTime).format('HH:mm')}～${moment(ws.endTime).format(
          'HH:mm'
        )} ${ws.isStartTimeBreakTime ? '(休憩時間)' : ''} `
      )
    }
    return result
  }

  return (
    <div>
      <Formik
        initialValues={{
          functionName: '',
          doctor: workSchedule.doctor,
          confirm: false,
          accepting: false,
          deleting: false,
          rejecting: false,
          updating: false,
          isSendMail: false,
          fullname: workSchedule.doctor.fullName,
          fullnameKana: workSchedule.doctor.fullNameKana,
          clinicalDepartment: workSchedule.clinicalDepartment,
          clinicName: ClinicNameRender({
            clinicalDepartment: workSchedule.clinicalDepartment,
          }),
          departmentName: workSchedule.clinicalDepartment.name,
          scheduleDate: moment(workSchedule.startTime).format('LL (dd)'),
          workScheduleComment: workSchedule.comment,
          date: moment(workSchedule.startTime).toDate(),
          startTime: moment(workSchedule.startTime).toDate(),
          splits: splitLogicReverse({
            availableShift: workSchedule.acceptedShift,
          }),
          deletedStatus: workSchedule.deletedStatus,
          accepted: workSchedule.accepted,
          deletedReason: workSchedule.deletedReason,
          rejectedReason: workSchedule.rejectedReason,
          acceptedReason:
            workSchedule.doctor.firstWorkday === null
              ? DetailAvailableShiftTemplateMail.props.children
              : workSchedule.acceptedReason,
          doctorWorkSchedules: workSchedule.doctorWorkSchedules,
          introductionHistory:
            workSchedule.acceptedShift.introductionHistory || '',
          introductionHistoryOther:
            workSchedule.acceptedShift.introductionHistoryOther || '',
          comment: workSchedule.acceptedShift.comment || '',
          doctorId: workSchedule.doctor.id || '',
          actualTime: workSchedule.workScheduleActualTime
            ? actualTime(workSchedule.workScheduleActualTime)
            : '',
          doctorSubsidy:
            workSchedule &&
            workSchedule.acceptedShift &&
            workSchedule.acceptedShift.doctorSubsidy
              ? workSchedule.acceptedShift.doctorSubsidy
              : [],
          endTime: moment(workSchedule.endTime),
          isSendIntroduceMess: false,
          isPublished:
            workSchedule && workSchedule.acceptedShift
              ? workSchedule.acceptedShift.isPublished
              : true,
          isDoubleRecruitment:
            workSchedule && workSchedule.acceptedShift
              ? workSchedule.acceptedShift.isDoubleRecruitment
              : true,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            initialValues,
            values,
            validateForm,
            setTouched,
            dirty,
            touched,
            errors,
            setFieldValue,
          } = formikProps
          let confirmationMessage
          switch (values.functionName) {
            case accept:
              confirmationMessage = i18n.t(
                'staff.workSchedule.acceptConfirmation'
              )
              break
            case cancel:
              confirmationMessage = i18n.t(
                'staff.workSchedule.cancelConfirmation'
              )
              break
            case reject:
              confirmationMessage = i18n.t(
                'staff.workSchedule.rejectConfirmation'
              )
              break
            case update:
              confirmationMessage = !values.isSendMail
                ? i18n.t('staff.workSchedule.updateConfirmation')
                : i18n.t('staff.workSchedule.updateConfirmationNotSendMail')
              break
            case restore:
              confirmationMessage = i18n.t(
                'staff.workSchedule.restoreConfirmation'
              )
              break
            default:
              break
          }

          const validateAndConfirm = () =>
            validateForm().then(response => {
              if (!Object.keys(response).length) {
                setFieldValue('confirm', true)
              } else {
                setTouched(response)
              }
            })
          return (
            <TabView>
              <TabPanel header={i18n.t('main.workScheduleDetailTab.detail')}>
                <div className=''>
                  {values.deletedStatus === 'WORKSCHEDULE_CANCEL' ? (
                    <div className='modal-title'>
                      {i18n.t('main.status')}: {i18n.t('main.ACCEPTED')}
                      {i18n.t('main.cancel')}
                    </div>
                  ) : (
                    <div className='modal-title'>
                      {i18n.t('main.status')}:
                      {values.accepted
                        ? i18n.t('main.ACCEPTED')
                        : i18n.t('main.NOT_ACCEPTED')}
                      {i18n.t('main.shift')}
                    </div>
                  )}
                  {workSchedule.acceptedShift.name && (
                    <div className='tr'>
                      <div className='th'>{i18n.t('main.shiftTitle')}</div>
                      <div className='td' style={{ paddingLeft: '20px' }}>
                        {workSchedule.acceptedShift.name}
                      </div>
                    </div>
                  )}
                  <TextInput
                    {...formikProps}
                    name='fullname'
                    label={i18n.t('main.fullname')}
                    disabled
                    classDisabled
                    doctorId={workSchedule.doctor.id}
                  />
                  <TextInput
                    {...formikProps}
                    name='fullnameKana'
                    label={i18n.t('main.fullnameKana')}
                    disabled
                    classDisabled
                  />
                  <TextInput
                    {...formikProps}
                    name='clinicName'
                    label={i18n.t('main.clinicName')}
                    disabled
                    classDisabled
                  />
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.departmentName')}</div>
                    <div className='td' style={{ paddingLeft: '20px' }}>
                      {departmentNames[values.departmentName]}
                    </div>
                  </div>
                  <TextInput
                    {...formikProps}
                    name='scheduleDate'
                    label={i18n.t('main.scheduleDate')}
                    disabled
                    classDisabled
                  />
                  {isMoneyPermitted ? (
                    <div className='tr'>
                      <div
                        style={{
                          color: 'red',
                        }}
                        className='th'
                      >
                        二診目登録
                      </div>
                      <div className='td checkbox-color-border'>
                        <CheckboxAntd
                          checked={values.isDoubleRecruitment}
                          onChange={e => {
                            setFieldValue(
                              'isDoubleRecruitment',
                              e.target.checked
                            )
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                  <InputTextarea
                    {...formikProps}
                    name='comment'
                    label={i18n.t('main.workDetail')}
                    autoResize
                    rows={8}
                  />
                  <div className='tr'>
                    <div className='th'>
                      {i18n.t('staff.scheduledShiftGroup.tittlePublished')}
                    </div>
                    <div className='td toggle-container'>
                      <Switch
                        name='isPublished'
                        checkedChildren={i18n.t(
                          'staff.scheduledShiftGroup.published'
                        )}
                        unCheckedChildren={i18n.t(
                          'staff.scheduledShiftGroup.notPublished'
                        )}
                        checked={values.isPublished}
                        onChange={e => {
                          if (isChangeConfirmedShiftsStatusPermitted) {
                            setFieldValue('isPublished', e)
                            if (!e.checked) {
                              setFieldValue('isSendMail', false)
                            }
                          } else {
                            popup.error(
                              i18n.t(
                                'staff.scheduledShiftGroup.errorMessagePermision'
                              )
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      workSchedule.comment
                        ? 'doctor-comment'
                        : 'doctor-no-comment'
                    }
                  >
                    {workSchedule.comment ? (
                      <div className='doctor-comment-container'>
                        <div className='th doctor-comment-title'>
                          {i18n.t('main.workScheduleComment')}
                        </div>
                        <br />
                        <div className='doctor-comment-content'>
                          <div className='comment-full'>
                            {workSchedule.comment}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='doctor-comment-container'>
                        <div className='th doctor-comment-title'>
                          {i18n.t('main.workScheduleComment')}
                        </div>
                        <br />
                        <div className='doctor-comment-content'>
                          <div className='comment-empty'>
                            {i18n.t('main.staffComment.emptyComment')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {isAddPermitted && (
                    <div className='staffpage-comment-container'>
                      <div className='staffpage-title'>
                        {i18n.t('main.staffComment.titleStaffComment')}
                      </div>
                      <WorkScheduleComment
                        comments={workSchedule.staffComments}
                        availableShiftGroupComment={
                          workSchedule.acceptedShift
                            .scheduledAvailableShiftGroup
                            ? workSchedule.acceptedShift
                                .scheduledAvailableShiftGroup.staffComments
                            : null
                        }
                        workScheduleId={workSchedule.id}
                        staffId={staffMe.id}
                        isMoneyPermitted={isMoneyPermitted}
                        isAddPermitted={isAddPermitted}
                        isDeletePermitted={isDeletePermitted}
                        isDeleteAllComment={isDeleteAllComment}
                      />
                    </div>
                  )}
                  <WorkScheduleType
                    type={workSchedule.acceptedShift.type || ''}
                    workScheduleId={workSchedule.acceptedShift.id}
                    staffId={staffMe.id}
                  />
                  <ShiftFormSplits
                    {...formikProps}
                    i18n={i18n}
                    disabled={values.deletedStatus}
                    classDisabled
                    isMoneyPermitted={isMoneyPermitted}
                    doctorHourlyWageRange={doctorHourlyWageRange}
                    isDetailWorkSchedule={true}
                    dataDoctorConsultationTime={dataDoctorConsultationTime}
                    isViewCountWorkScheduleDetailPermitted={isViewCountWorkScheduleDetailPermitted}
                  />
                  <div>
                    <FieldArray
                      name='doctorSubsidy'
                      render={arrayHelpers => (
                        <div>
                          {values.doctorSubsidy.map((item, index) => (
                            <div className='doctor-subsidy-container'>
                              <div className='doctor-subsidy__item'>
                                {i18n.t('staff.workSchedule.doctorSubsidy')}
                              </div>
                              <div className='doctor-subsidy__item'>
                                <div className='title'>
                                  {i18n.t('staff.workSchedule.subsidyName')}
                                </div>
                                <div>
                                  <InputText
                                    value={item.title}
                                    onChange={e => {
                                      setFieldValue(
                                        `doctorSubsidy[${index}].title`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorSubsidy[${index}].title`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </div>
                              </div>
                              <div className='doctor-subsidy__item'>
                                <div className='title'>
                                  {i18n.t('staff.workSchedule.subsidyValue')}
                                </div>
                                <div>
                                  <InputNumber
                                    size='medium'
                                    value={item.money}
                                    allowClear
                                    min={0}
                                    step={10}
                                    formatter={value =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                      )
                                    }
                                    type='tel'
                                    onChange={e => {
                                      setFieldValue(
                                        `doctorSubsidy[${index}].money`,
                                        e
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorSubsidy[${index}].money`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </div>
                              </div>
                              <div className='doctor-subsidy__item'>
                                <Button
                                  type='button'
                                  icon='pi pi-trash'
                                  onClick={async () => {
                                    arrayHelpers.remove(index)
                                    if (item.id) {
                                      await staffDeleteDoctorSubsidy({
                                        variables: {
                                          doctorSubsidyId: item.id,
                                        },
                                      })
                                    }
                                  }}
                                />
                              </div>
                              {/* <div className='errors-container'>
                               
                              </div> */}
                            </div>
                          ))}
                        </div>
                      )}
                    />
                    <Button
                      type='button'
                      label={i18n.t('staff.workSchedule.btnSubsidy')}
                      icon='pi pi-plus'
                      onClick={() => {
                        setFieldValue('doctorSubsidy', [
                          ...values.doctorSubsidy,
                          {
                            title: '',
                            money: null,
                          },
                        ])
                      }}
                    />
                  </div>
                  <div className='tr'>
                    <div className='th'>
                      {i18n.t('staff.createDoctor.adjustHourlyWageRange')}
                    </div>
                    <div className='td'>
                      {numeralCurrency(doctorHourlyWageRange)}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.relatedShifts')}</div>
                  </div>
                  {values.doctorWorkSchedules.length > 0 ? (
                    <React.Fragment>
                      <div style={styles.related}>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>{i18n.t('main.clinicName')}</th>
                              <th>{i18n.t('main.departmentName')}</th>
                              <th>{i18n.t('main.date')}</th>
                              <th>{i18n.t('main.startTime')}</th>
                              <th>{i18n.t('main.endTime')}</th>
                              <th>{i18n.t('main.status')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.doctorWorkSchedules.map(
                              ({
                                id,
                                startTime,
                                endTime,
                                accepted,
                                clinicalDepartment,
                                deletedStatus,
                              }) =>
                                deletedStatus === 'WORKSCHEDULE_REJECT' ||
                                deletedStatus ===
                                  'WORKSCHEDULE_DELETE' ? null : (
                                  <tr key={id}>
                                    <td>
                                      {ClinicNameRender({ clinicalDepartment })}
                                    </td>
                                    <td>
                                      {departmentNames[clinicalDepartment.name]}
                                    </td>
                                    <td>{moment(startTime).format('LL')}</td>
                                    <td>{moment(startTime).format('HH:mm')}</td>
                                    <td>{moment(endTime).format('HH:mm')}</td>
                                    {deletedStatus === 'WORKSCHEDULE_CANCEL' ? (
                                      <td>
                                        {i18n.t('main.workScheduleCanceled')}
                                      </td>
                                    ) : (
                                      <td>
                                        {accepted
                                          ? i18n.t('main.ACCEPTED')
                                          : i18n.t('main.NOT_ACCEPTED')}
                                      </td>
                                    )}
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div style={styles.empty}>
                      {i18n.t('staff.workSchedule.noWorkSchedule')}
                    </div>
                  )}
                  <div className='tr'>
                    <div className='th'>
                      {i18n.t('staff.availableShift.reasonKnowAboutShift')}
                    </div>
                    <div className='td'>
                      <CustomDropdown
                        {...formikProps}
                        style={{
                          minWidth: '300px',
                        }}
                        name='introductionHistory'
                        options={optionsKnowAboutShift}
                        placeholder={i18n.t(
                          'staff.availableShift.placeholderSelectReasonKnowAboutShift'
                        )}
                        changed={values.introductionHistory ? 'changed' : ''}
                      />
                    </div>
                  </div>
                  {values.introductionHistory === 'OTHER' ? (
                    <div className='tr'>
                      <div className='th'>
                        {i18n.t(
                          'staff.availableShift.detailReasonKnowAboutShift'
                        )}
                      </div>
                      <div className='td'>
                        <InputTextarea
                          {...formikProps}
                          name='introductionHistoryOther'
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.postUpdateHistory')}</div>
                    <div className='td'>
                      {true &&
                        postUpdateHistory.map((it, k) => (
                          <div key={k}>
                            <span>
                              {moment(it.createdAt)
                                .startOf('day')
                                .format('YYYY年MM月DD日')}
                            </span>
                            <span>
                              {' '}
                              {moment(it.createdAt).format('HH:mm')}{' '}
                            </span>
                            <span> {it.staff} </span>
                            <span> {it.activity} </span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.updateHistory')}</div>
                    <div className='td'>
                      {activitiesHistory &&
                        activitiesHistory.map(it => (
                          <div key={it.id}>
                            <span>
                              {moment(it.createdAt)
                                .startOf('day')
                                .format('YYYY年MM月DD日')}
                            </span>
                            <span>{moment(it.createdAt).format('HH:mm')}</span>
                            <span>
                              {it.staff.lastname} {it.staff.firstname}
                            </span>
                            <span>
                              {i18n.t(
                                `staff.activityLogsStaffHistory.${it.activity}`
                              )}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                  <Accordion style={{ marginTop: '5px' }}>
                    <AccordionTab header={`${i18n.t('main.acceptedReason')}`}>
                      {!workSchedule.acceptedReason &&
                      !workSchedule.deletedReason ? (
                        <div className='tr'>
                          <div className='th' />
                          <div className='td'>
                            {i18n.t('main.emptyMessage')}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='tr'>
                            <div className='th'>
                              {i18n.t('main.acceptedReasonMessage')} :
                            </div>
                            <div className='td hidden-header'>
                              <Editor value={values.acceptedReason} readOnly />
                            </div>
                          </div>
                          <div className='tr'>
                            <div className='th'>
                              {i18n.t('main.deletedReasonCancel')} :
                            </div>
                            <div className='td'>
                              {workSchedule.deletedReason}
                            </div>
                          </div>
                        </>
                      )}
                    </AccordionTab>
                  </Accordion>
                  {!!state?.isshinTime && (
                    <div
                      className='tr'
                      style={{ display: 'flex', alignItems: 'start' }}
                    >
                      <div className='th'>
                        {i18n.t('main.consultationShiftTime')}
                      </div>
                      <div className='td'>
                        {state.isshinTime.split(', ').map(time => (
                          <>
                            {time}
                            <br />
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {isGoldWare && (
                    <p style={{ color: 'red' }}>
                      ※確定シフト更新及びキャンセルについて、GoldWareアカウントの場合はメールが送信されません。
                    </p>
                  )}
                  <div className='modal-bottom'>
                    {values.deletedStatus === 'WORKSCHEDULE_CANCEL' &&
                    isRestorePermitted ? (
                      <Button
                        onClick={() => {
                          setFieldValue('functionName', restore)
                          setFieldValue('confirm', true)
                        }}
                        label='確定シフトキャンセル取消'
                        className='p-button-success'
                      />
                    ) : null}
                    {!values.accepted && (
                      <>
                        {isAcceptPermitted ? (
                          <Button
                            onClick={() => {
                              validateForm().then(response => {
                                if (
                                  !Object.keys(response).filter(
                                    key =>
                                      ![
                                        'acceptedReason',
                                        'deletedReason',
                                        'rejectedReason',
                                      ].includes(key)
                                  ).length
                                ) {
                                  setFieldValue('functionName', accept)
                                  setFieldValue('accepting', true)
                                } else {
                                  setTouched(response)
                                }
                              })
                            }}
                            label={i18n.t('staff.acceptWorkSchedule.submit')}
                            className='p-button-success'
                            icon='pi pi-check'
                          />
                        ) : null}
                        {isRejectPermitted ? (
                          <Button
                            onClick={() => {
                              setFieldValue('functionName', reject)
                              setFieldValue('rejecting', true)
                            }}
                            label={i18n.t('staff.rejectWorkSchedule.submit')}
                            className='p-button-danger'
                            icon='pi pi-trash'
                          />
                        ) : null}
                      </>
                    )}

                    {values.accepted && !values.deletedStatus ? (
                      <>
                        {isCancelPermitted ? (
                          <Button
                            onClick={() => {
                              setFieldValue('functionName', cancel)
                              setFieldValue('deleting', true)
                            }}
                            label={i18n.t('staff.cancelWorkSchedule.submit')}
                            className='p-button-danger'
                            icon='pi pi-trash'
                          />
                        ) : null}
                        {isUpdatePermitted ? (
                          <Button
                            label={i18n.t('staff.workSchedule.update')}
                            disabled={!dirty}
                            onClick={() => {
                              validateForm().then(response => {
                                if (
                                  !Object.keys(response).filter(
                                    key =>
                                      ![
                                        'acceptedReason',
                                        'deletedReason',
                                        'rejectedReason',
                                      ].includes(key)
                                  ).length
                                ) {
                                  setFieldValue('functionName', update)
                                  setFieldValue('updating', true)
                                } else {
                                  setTouched(response)
                                }
                              })
                            }}
                            icon='pi pi-pencil'
                          />
                        ) : null}
                        <Link
                          to={{
                            pathname: `${location.pathname
                              .split('/')
                              .slice(0, -2)
                              .join('/')
                              .replace(
                                'accepted_work_schedules_list',
                                'available_shifts'
                              )}${Router.staffCreateWorkSchedule}`,
                            states: {
                              ...values,
                              doctor: null,
                            },
                          }}
                        >
                          <Button label='複製' />
                        </Link>
                      </>
                    ) : null}
                  </div>
                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={confirmationMessage}
                  />
                  <ConfirmationModal
                    {...formikProps}
                    handleSubmit={validateAndConfirm}
                    handleNoClick={() => {
                      setFieldValue('updating', false)
                      setFieldValue(
                        'acceptedReason',
                        initialValues.acceptedReason
                      )
                    }}
                    confirmationMessage={confirmationMessage}
                    visibleKey='updating'
                    isSendMail
                  >
                    {values.functionName === update && (
                      <div className='tr'>
                        <div className='th'>
                          {i18n.t('main.acceptedReason')}
                        </div>
                        <div className='td'>
                          <Editor
                            className='editor-responsive'
                            onTextChange={e =>
                              setFieldValue('acceptedReason', e.htmlValue)
                            }
                            value={values.acceptedReason}
                          />
                        </div>
                        <div className='alert'>
                          {touched.acceptedReason && errors.acceptedReason}
                        </div>
                      </div>
                      // <InputTextarea
                      //   {...formikProps}
                      //   name='acceptedReason'
                      //   label={i18n.t('main.acceptedReason')}
                      // />
                    )}
                  </ConfirmationModal>
                  <ConfirmationModal
                    {...formikProps}
                    handleSubmit={validateAndConfirm}
                    handleNoClick={() => {
                      setFieldValue('accepting', false)
                      setFieldValue(
                        'acceptedReason',
                        initialValues.acceptedReason
                      )
                    }}
                    confirmationMessage={confirmationMessage}
                    visibleKey='accepting'
                  >
                    {values.functionName === accept && (
                      <>
                        <TextInput
                          {...formikProps}
                          name='fullname'
                          label={i18n.t('staff.workSchedule.doctorApply')}
                          classDisabled
                          disabled
                        />
                        <div className='tr'>
                          <div className='th'>{i18n.t('main.workplace')}</div>
                          <div className='td' style={{ paddingLeft: '20px' }}>
                            {`${values.clinicName}_${
                              departmentNames[values.departmentName]
                            }`}
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th'>
                            {i18n.t(
                              'staff.workSchedule.noDoctorWorkedInClinic'
                            )}
                          </div>
                          <div className='td' style={{ paddingLeft: '20px' }}>
                            {countDoctorWorkedInClinic}
                          </div>
                        </div>
                        <div className='tr'>
                          <div className='th'>
                            {i18n.t('staff.workSchedule.isSendIntroduceMess')}
                          </div>
                          <div className='td' style={{ paddingLeft: '20px' }}>
                            <Checkbox
                              onChange={e => {
                                setFieldValue('isSendIntroduceMess', e.checked)
                                if (e.checked) {
                                  setFieldValue(
                                    'acceptedReason',
                                    workSchedule.clinicalDepartment.clinic
                                      .messageIntroduce
                                  )
                                }
                              }}
                              checked={values.isSendIntroduceMess}
                            />
                          </div>
                        </div>
                        {/* <InputTextarea
                          {...formikProps}
                          name='acceptedReason'
                          label={i18n.t('main.acceptedReason')}
                        /> */}
                        <div className='tr'>
                          <div className='th'>
                            {i18n.t('main.acceptedReason')}
                          </div>
                          <div className='td'>
                            <Editor
                              className='editor-responsive'
                              onTextChange={e =>
                                setFieldValue('acceptedReason', e.htmlValue)
                              }
                              value={values.acceptedReason}
                            />
                          </div>
                          <div className='alert'>
                            {touched.acceptedReason && errors.acceptedReason}
                          </div>
                        </div>
                      </>
                    )}
                  </ConfirmationModal>
                  <ConfirmationModal
                    {...formikProps}
                    handleSubmit={validateAndConfirm}
                    handleNoClick={() => {
                      setFieldValue('deleting', false)
                      setFieldValue(
                        'deletedReason',
                        initialValues.deletedReason
                      )
                    }}
                    confirmationMessage={confirmationMessage}
                    visibleKey='deleting'
                  >
                    {values.functionName === cancel && (
                      // <InputTextarea
                      //   {...formikProps}
                      //   name='deletedReason'
                      //   label={i18n.t('main.deletedReason')}
                      // />
                      <div className='tr'>
                        <div className='th'>{i18n.t('main.deletedReason')}</div>
                        <div className='td'>
                          <Editor
                            className='editor-responsive'
                            onTextChange={e =>
                              setFieldValue('deletedReason', e.htmlValue)
                            }
                            value={values.deletedReason}
                          />
                        </div>
                        <div className='alert'>
                          {touched.deletedReason && errors.deletedReason}
                        </div>
                      </div>
                    )}
                  </ConfirmationModal>
                  <ConfirmationModal
                    {...formikProps}
                    handleSubmit={validateAndConfirm}
                    handleNoClick={() => {
                      setFieldValue('rejecting', false)
                      setFieldValue(
                        'rejectedReason',
                        initialValues.rejectedReason
                      )
                    }}
                    confirmationMessage={confirmationMessage}
                    visibleKey='rejecting'
                  >
                    {values.functionName === reject && (
                      // <InputTextarea
                      //   {...formikProps}
                      //   name='rejectedReason'
                      //   label={`${i18n.t('main.deletedReason')}`}
                      // />
                      <div className='tr'>
                        <div className='th'>{i18n.t('main.deletedReason')}</div>
                        <div className='td'>
                          <Editor
                            className='editor-responsive'
                            onTextChange={e =>
                              setFieldValue('rejectedReason', e.htmlValue)
                            }
                            value={values.rejectedReason}
                          />
                        </div>
                        <div className='alert'>
                          {touched.rejectedReason && errors.rejectedReason}
                        </div>
                      </div>
                    )}
                  </ConfirmationModal>
                </div>
              </TabPanel>
              <TabPanel header={i18n.t('main.workScheduleDetailTab.comments')}>
                <div>
                  <div
                    className={
                      workSchedule.comment
                        ? 'doctor-comment'
                        : 'doctor-no-comment'
                    }
                  >
                    {workSchedule.comment ? (
                      <div className='doctor-comment-container'>
                        <div className='th'>
                          {i18n.t('main.workScheduleComment')}
                        </div>
                        <br />
                        <div className='doctor-comment-content'>
                          {workSchedule.comment}
                        </div>
                      </div>
                    ) : (
                      <div className='doctor-comment-container'>
                        <div className='th doctor-comment-title'>
                          {i18n.t('main.workScheduleComment')}
                        </div>
                        <br />
                        <div className='doctor-comment-content'>
                          {i18n.t('main.staffComment.emptyComment')}
                        </div>
                      </div>
                    )}
                  </div>
                  {isAddPermitted && (
                    <div className='staffpage-comment-container'>
                      <div className='staffpage-title'>
                        {i18n.t('main.staffComment.titleStaffComment')}
                      </div>
                      <WorkScheduleComment
                        comments={workSchedule.staffComments}
                        availableShiftGroupComment={
                          workSchedule.acceptedShift
                            .scheduledAvailableShiftGroup?.staffComments || null
                        }
                        workScheduleId={workSchedule.id}
                        staffId={staffMe.id}
                        isMoneyPermitted={isMoneyPermitted}
                        isAddPermitted={isAddPermitted}
                        isDeletePermitted={isDeletePermitted}
                        isDeleteAllComment={isDeleteAllComment}
                        isTabPanel
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel header={i18n.t('main.workScheduleDetailTab.workType')}>
                <WorkScheduleType
                  type={workSchedule.acceptedShift.type || ''}
                  workScheduleId={workSchedule.acceptedShift.id}
                  staffId={staffMe.id}
                  isTabPanel
                />
              </TabPanel>
            </TabView>
          )
        }}
      />
    </div>
  )
}

export default withRouter(DetailWorkScheduleScene)
