const routes = {
  home: '/',
  confirmEmail: '/resetEmail',
  // Doctor Routes
  doctorTerms: '/doctor/terms_conditions',
  doctorPrivacy: '/doctor/privacy_policy',
  doctorEmailConfirm: '/doctor/email_confirm',
  doctorLogin: '/doctor/login',
  doctorForgotPassword: '/doctor/forgot_password',
  doctorSignup: '/doctor/signup',
  doctorSignupSuccess: '/doctor/signup_success',
  doctorResetPassword: '/doctor/reset_password',
  doctorHome: '/doctor/',
  doctorMyPage: '/doctor/my_page',
  doctorEmailNotificationsSetting: '/doctor/email_notifications_setting',
  doctorAvailableShiftsWithoutYearMonth: '/doctor/available_shifts',
  doctorAvailableShiftsWithoutMonth: '/doctor/available_shifts/:year',
  doctorAvailableShifts: '/doctor/available_shifts/:year/:month',
  doctorAvailableShiftsFirstLogin:
    '/doctor/first_login_available_shifts/:year/:month',
  doctorWorkReportsWithoutYearMonth: '/doctor/work_reports',
  doctorWorkReportsWithoutMonth: '/doctor/work_reports/:year',
  doctorWorkReports: '/doctor/work_reports/:year/:month',
  doctorRecruitments: '/doctor/recruitments',
  doctorRecruitmentDetail: '/doctor/recruitment/detail/:recruitmentId',
  doctorRecruitmentDetailApply: '/apply',
  doctorTransportReports: '/doctor/transport_reports/:year/:month',
  doctorTransportByCarReports: '/doctor/car_transport_reports/:year/:month',
  doctorTransportReportsWithoutYearMonth: '/doctor/transport_reports',
  doctorTransportReportsWithoutMonth: '/doctor/transport_reports/:year',
  doctorTransportRegister: '/doctor/transport_register',
  doctorTransportPeriodic: '/doctor/transport_periodic',
  doctorAcceptedWorkSchedulesListWithoutYearMonth:
    '/doctor/work_schedules_list',
  doctorAcceptedWorkSchedulesListWithoutMonth:
    '/doctor/work_schedules_list/:year',
  doctorAcceptedWorkSchedulesList: '/doctor/work_schedules_list/:year/:month',
  doctorAcceptedWorkSchedulesOverTime: '/over_time/:workScheduleId',
  doctorAcceptedWorkScheduleDetail: '/detail/:workScheduleId',
  doctorAcceptedWorkScheduleCancel: '/cancel',
  doctorRequestedWorkSchedulesListWithoutYearMonth:
    '/doctor/request_schedules_list',
  doctorRequestedWorkSchedulesListWithoutMonth:
    '/doctor/request_schedules_list/:year',
  doctorRequestedWorkSchedulesList:
    '/doctor/request_schedules_list/:year/:month',

  //  doctorRequestedAvailableShiftsList: '/doctor/request_available_shifts_list',

  doctorCart: '/doctor/cart',
  doctorSchedulesWithoutYearMonth: '/doctor/schedules',
  doctorSchedulesWithoutMonth: '/doctor/schedules/:year',
  doctorSchedules: '/doctor/schedules/:year/:month',
  doctorSingleAvailableShift: '/available_shift/:availableShiftId',
  doctorNews: '/doctor/news',
  doctorNewsDetail: '/doctor/news/detail/:newsId/*',
  doctorInquiry: '/doctor/inquiry',
  doctorWorkOutsideClinic: '/doctor/work_outside_clinic',
  doctorWorkOutsideClinicDetail: '/detail/:id',
  doctorWorkOutsideClinicCreate: '/create',
  doctorPortal: '/doctor/portal',
  doctorClinic: '/doctor/clinics',
  doctorClinicDetail: '/clinic/detail/:id',
  doctorWaitingRecruitment: '/doctor/waiting_recruitment',
  doctorWarningRegister: '/doctor/warning_register',

  // Doctor Modal Routes
  doctorSingleSchedule: '/work_schedule/:workScheduleId',
  // doctorSingleAvailableShift: '/available_shift/:availableShiftId',
  doctorUpdateProfile: '/doctor/update_profile',
  DoctorCreateIdentity: '/doctor/create_identity',
  doctorChangePassword: '/doctor/change_password',
  doctorBankAccount: '/doctor/bank_account',
  doctorUploadFile: '/doctor/upload_file/:fileCategory',
  doctorUploadFileOther: '/doctor/upload_file_other/:fileCategory/:fileId',
  doctorQuestions: '/doctor/frequently_asked_questions',
  createWaitingRecruitment: '/create',
  detailWaitingRecruitment: '/detail/:id',

  // Staff Routes
  staffLogin: '/staff/login',
  staffHome: '/staff/',
  staffMyPage: '/staff/my_page',
  staffEmailConfirm: '/staff/email_confirm',
  staffForgotPassword: '/staff/forgot_password',
  staffResetPassword: '/staff/reset_password',
  staffChangePassword: '/staff/change_password',
  staffClinicsManage: '/staff/clinics/management',
  staffClinics: '/staff/clinics',
  staffStaffs: '/staff/staffs',
  staffGroups: '/staff/staff_groups',
  staffGroupDetail: '/staff/staff_group/detail/:staffGroupId',
  staffGroupCreate: '/staff/staff_group/create',
  staffDoctors: '/staff/doctors',
  staffAvailableShiftGroupList: '/staff/available_shift_group_list',
  staffDoctorsPending: '/staff/doctor_pending',
  staffDoctorsDeleteRequestList: '/staff/doctor_delete_request',
  staffDoctorWorkRecords: '/staff/doctor_work',
  staffDoctorWorkReport: '/staff/doctor_work_detail/:id/report/:year/:month',
  staffDoctorWorkReportWithoutYearMonth:
    '/staff/doctors_work_detail/:id/report',
  staffDoctorWorkReportWithoutMonth:
    '/staff/doctors_work_detail/:id/report/:year',
  staffDoctorConsultation: '/staff/doctorConsultation',
  staffPatientSatisfactionResults: '/staff/doctor/patient_satisfaction_results',
  staffPatientSatisfactionResultsByYear:
    '/staff/doctor/:id/patient_satisfaction_year/:year',
  staffPatientSatisfactionResultsByYearWithoutYear:
    '/staff/doctor/:id/patient_satisfaction_year',
  staffPatientSatisfaction:
    '/staff/doctor/:id/patient_satisfaction/:year/:month/:date',
  staffPatientSatisfactionWithoutDate:
    '/staff/doctor/:id/patient_satisfaction/:year/:month',
  staffPatientSatisfactionWithoutMonthDate:
    '/staff/doctor/:id/patient_satisfaction/:year',
  staffPatientSatisfactionWithoutYearMonthDate:
    '/staff/doctor/:id/patient_satisfaction',
  staffInquiries: '/staff/inquiries',
  staffQuestions: '/staff/questions',
  staffQuestionCreate: '/staff/question/create',
  staffQuestionUpdate: '/staff/question/update/:questionId',
  staffQuestionCategory: '/category',
  staffAnnounces: '/staff/announces',
  staffAnnounceUpdate: '/staff/announce/update/:announceId',
  staffAnnounceCreate: '/staff/announce/create',
  staffWorkScheduleCsv: '/staff/work_schedules/csv_upload',
  staffMonthlyAvailableShiftsWithoutYearMonth: '/staff/available_shifts',
  staffMonthlyAvailableShiftsWithoutMonth: '/staff/available_shifts/:year',
  staffMonthlyAvailableShifts: '/staff/available_shifts/:year/:month',
  staffOverTime: '/staff/over_times',
  staffOverTimeDetail: '/detail/:workOverTimeId',
  staffDailyAvailableShiftsWithoutYearMonthDay: '/staff/available_shifts_date',
  staffCancelShifts: '/staff/cancel_shifts',
  staffCancelShiftsDetail: '/detail/:cancelShiftId',
  staffDailyAvailableShiftsWithoutMonthDay:
    '/staff/available_shifts_date/:year',
  staffDailyAvailableShiftsWithoutDay:
    '/staff/available_shifts_date/:year/:month',
  staffDailyAvailableShifts: '/staff/available_shifts_date/:year/:month/:day',
  staffHourlyWages: '/staff/hourlyWages',
  staffHourlyWageCsv: '/staff/hourly_wage/csv_upload',
  staffHourlyPayment: '/staff/hourly_payment',
  staffHourlyWageChangeReservation:'/staff/early_hour_wage_setting',
  staffCreateDoctorHourlyWage: '/create',
  staffDetailDoctorHourlyWage: '/detail/:id',
  staffRecruitmentScheduleShift: '/staff/recruitment_schedule_shift_list',
  staffRecruitmentScheduleShiftCreate: '/create',
  staffRecruitmentScheduleShiftDetail: '/detail/:recruitmentScheduleid',
  staffRecruitmentUnpublished: '/staff/recruitment_unpublished/:year/:month',
  staffUploadDoctorCsv: '/staff/doctor/upload_csv',
  uploadDoctorCsvUpdate: '/staff/doctor/upload_csv_update',
  staffWaitingRecruitment: '/staff/waiting_recruitment',
  staffWorkOutsideClinic: '/staff/work_outside_clinic',
  staffWorkOutsideClinicDetail: '/detail/:id',
  // Staff Modal Routes
  staffUpdateProfile: '/staff/updateProfile',
  staffDetailAvailableShift: '/available_shifts/:availableShiftId',
  staffDetailWorkSchedule: '/work_schedules/:workScheduleId',
  staffDetailDoctorIdentity: '/doctor_identity/:doctorId',
  staffManangerDoctoridentity: '/manage_doctor_identity/:doctorId',
  staffCreateAvailableShift: '/available_shift/create',
  staffCreateAvailableShiftYearMonthDay:
    '/:year/:month/:day/available_shift/create',
  staffCreateScheduledShiftGroup: '/scheduled_shift_group/create',
  staffDetailScheduledShiftGroup:
    '/scheduled_shift_group/detail/:scheduledShiftGroupId/',
  staffCreateWorkSchedule: '/work_schedule/create',
  staffUpdateAvailableShift: '/available_shifts/:availableShiftId/update',
  staffInquiryDetail: '/inquiry/detail/:inquiryId',
  staffClinicDetail: '/clinic/detail/:clinicId',
  staffClinicUpdate: '/clinic/update/:clinicId',
  staffStaffCreate: '/staff/create',
  staffStaffDetail: '/staff/detail/:staffId',
  staffStaffUpdate: '/staff/update/:staffId',
  staffDoctorCreate: '/doctor/create',
  staffDoctorDetail: '/doctor/detail/:doctorId',
  staffWorkSchedulesListWithoutYearMonth: '/staff/work_schedules_list',
  staffWorkSchedulesListWithoutMonth: '/staff/work_schedules_list/:year',
  staffWorkSchedulesList: '/staff/work_schedules_list/:year/:month',
  staffAcceptedWorkSchedulesListWithoutYearMonth:
    '/staff/accepted_work_schedules_list',
  staffAcceptedWorkSchedulesListWithoutMonth:
    '/staff/accepted_work_schedules_list/:year',
  staffAcceptedWorkSchedulesList:
    '/staff/accepted_work_schedules_list/:year/:month',
  staffAvailableShiftListWithoutYearMonth: '/staff/available_shift_list',
  staffAvailableShiftListWithoutMonth: '/staff/available_shift_list/:year',
  staffAvailableShiftList: '/staff/available_shift_list/:year/:month',
  staffActivityLogsList: '/staff/activity_logs/doctors',
  staffActivityLogsListUpdateByStaff: '/staff/activity_logs/staffs',
  staffActivityLogsListUpdateByStaffDetail:
    '/staff_activity_log/:activityLogId',
  staffActivityLogsListDoctorHistory: '/staff/activity_logs/doctor_histories',
  staffActivityLogsListStaffHistory: '/staff/activity_logs/staff_histories',
  staffActivityLogDetail: '/activity_log/:activityLogId',
  staffHourlyWageCreate: '/hourlyWage/create',
  staffHourlyWageDetail: '/hourlyWage/detail/:recruitmentShiftId',
  staffHourlyWageDuplicate: '/hourlyWage/duplicate/:recruitmentShiftId',
  staffDoctorsMovingExpenses: '/staff/moving_expenses',
  staffDoctorsMovingExpensesRegistration: '/staff/moving_expense_registration',
  staffDoctorsMovingExpensesRegistrationCreate: '/moving_expenses/create',
  staffDoctorsMovingExpensesRegistrationDetail: '/:transportationId',
  staffDoctorsMovingExpensesOtherDetail: '/:doctorId',
  staffDoctorsMovingExpensesRegistrationDetail:
    '/staff/moving_expenses_registration/:transportationId',
  staffDoctorsMovingExpensesOtherDetail:
    '/staff/moving_expenses_other/:doctorId',
  staffDoctorsMovingExpensesOther: '/staff/moving_expense_other',
  staffDoctorsMovingExpensesSetting: '/staff/moving_expense_setting',
  staffDoctorsMovingExpensesAddSetting: '/staff/moving_expense_setting/create',
  staffDoctorsMovingExpensesDetailSetting: '/detail/:id',
  staffDoctorsMovingExpensesDetail:
    '/staff/detail/moving_expenses/:doctorId/:year/:month',
  staffDoctorsMonthlyTransportationExpenses:
    '/staff/monthly_moving_expenses/:year/:month',
  staffDoctorsMovingExpensesCreate: '/create',
  staffDoctorsMovingExpensesUpdate: '/update/:transportationId',
  staffMyNumberManage: '/staff/my_number_manage',
  staffDoctorSkill: '/staff/list_skills',
  staffCreateDoctorSkill: '/skill/create',
  staffDetailDoctorSkill: '/:skillId',
  staffIncentive: '/staff/incentives',
  staffIncentiveDetail: '/incentives/detail/:id',
  staffIncentiveSetting: '/staff/incentive_setting',
  staffCreateIncentiveSettingCreate: '/incentive_setting/create',
  staffCreateIncentiveSettingDetail: '/incentive_setting/detail/:id',
  staffIncentiveCsvImport: '/incentive_import',
  staffNewDoctorsListWithoutYearMonth: '/staff/new_doctors',
  staffNewDoctorsListWithoutMonth: '/staff/new_doctors/:year',
  staffNewDoctorsList: '/staff/new_doctors/:year/:month',
  staffMonthlyDoctorSalaryConfirmationWithoutYearMonth:
    '/staff/monthly_doctor_salary_confirmation',
  staffMonthlyDoctorSalaryConfirmationWithoutMonth:
    '/staff/monthly_doctor_salary_confirmation/:year',
  staffMonthlyDoctorSalaryConfirmation:
    '/staff/monthly_doctor_salary_confirmation/:year/:month',
  downloadWorkScheduleCsv: '/staff/downloadWorkScheduleCsv/:year/:month',
  staffDocuments: '/staff/documents',
  staffDocumentUpdate: '/staff/document/update/:documentId',
  staffDocumentCreate: '/staff/document/create',
  staffPortals: '/staff/portals',
  staffPortalUpdate: '/staff/portal/update/:portalId',
  staffPortalCreate: '/staff/portal/create',
  staffLargePortalTitleList: '/staff/portal/large_title',
  staffMediumPortalTitleList: '/staff/portal/medium_title',
  staffWaitingRecruitmentDetail: '/detail/:id',
  // staffLargePortalTitleList: '/staff/portal/large_title',
  // staffMediumPortalTitleList: '/staff/portal/medium_title',
  staffRecruitmentPosts: '/staff/recruitment_post_list',
  staffRecruitmentPostUpdate:
    '/staff/recruitment_post/update/:recruitment_postId',
  staffRecruitmentPostCreate: '/staff/recruitment_post/create',
  staffRecruitmentDoctorList: '/staff/recruitment_doctor_list',
  staffRecruitmentDoctorDetail: '/staff/recruitment_doctor_detail/:id',
  doctorFindAvailableShift: '/doctor/Search_Result',
  staffManageDoctorTransportByPersonalCar: '/staff/moving_expense_personal_car',
  staffManageDoctorTransportByCarDetail:
    '/staff/detail/moving_expense_personal_car/:id',
  staffGasolinePrice: '/gasoline/setting',
  staffRegionEdit:'/region',
  staffDoctorConsultationTimeCsv: '/staff/doctorConsultation/csv_upload',
}

const Router = {
  ...routes,
  get: (pathInput, params, hashes) => {
    let path = pathInput
    if (params) {
      Object.keys(params).forEach(paramKey => {
        path = path.replace(`:${paramKey}`, params[paramKey])
      })
    }
    if (hashes) {
      Object.keys(hashes).forEach(hashKey => {
        path += `#${hashes[hashKey]}`
      })
    }
    return path
  },
}

export default Router
